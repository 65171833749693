import React from 'react'

const ModalLoader = () => {
    return (
        <div id="preloader modalpreloader">
            <div className="modal-sk-three-bounce sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        </div>
    )
}

export default ModalLoader;