// DetailPage.js
import React from 'react';
import products from './services.json'; // Import your JSON data
import { useParams } from "react-router-dom";
function DetailPage() {
  const { id } = useParams();
  const product = products.find(product => product.id === parseInt(id));

  return (
    <div>
      <h1>{product.name}</h1>
      <p>{product.description}</p>
    </div>
  );
}

export default DetailPage;