import React, { Fragment, useEffect, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

//** Import Image */
import profile from "../../../images/avatar/1.jpg";
import PageTitle from "../../layouts/PageTitle";
import { Button, Modal, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import DemoSlider from "../Property/DemoSlider";
import AdminService from "../../../services/AdminService";
import { Logout } from "../../../store/actions/AuthActions";
import Swal from "sweetalert2";
import Loader from "../Loader";

const PmProfile = () => {
  const { id } = useParams();
  const pmId = atob(id);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [pmSubscription, setPmSubscription] = useState({});
  const [showConfirmationModalForPlan, setShowConfirmationModalForPlan] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const handleSubscriptionChange = (e) => {
    setShowConfirmationModalForPlan(true);
    setSubscription(e.target.value);
  }

  let errorsObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    profile_image: "",
    lat_: "",
    long_: "",
    city: "",
    state: "",
    zip: "",
    fb_link: "",
    inst_link: "",
    website: "",
    telephone: "",
    inst_link: "",
    telephone_2: "",
    about_me: "",
    bank_account: "",
    tax_ID: "",
    company_name: "",
    company_logo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event, name) => {
    console.log("name = ", name);
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: imageUrl,
      }));
      if (name == 'profile_image') {
        console.log("profile_image imageUrl = ", imageUrl)
        setLogo(file);
      } else if (name == 'company_logo') {
        console.log("company_logo imageUrl = ", imageUrl)
        setcompanyLogo(file);
      }
    }
  };

  useEffect(() => {
    setLoader(true);
    AdminService.GetPropertyManagerById(pmId)
      .then((response) => {
        if (response.success) {
          const {
            first_name,
            last_name,
            email,
            address,
            profile_image,
            lat_,
            long_,
            city,
            state,
            zip,
            fb_link,
            inst_link,
            website,
            telephone,
            telephone_2,
            about_me,
            subscription,
            bank_account,
            tax_ID,
            company_logo,
            company_name
          } = response.data;
          setFormData({
            first_name: first_name ?? "",
            last_name: last_name ?? "",
            email: email ?? "",
            address: address ?? "",
            profile_image: profile_image ?? "",
            lat_: lat_ ?? "",
            long_: long_ ?? "",
            city: city ?? "",
            state: state ?? "",
            zip: zip ?? "",
            fb_link: fb_link ?? "",
            inst_link: inst_link ?? "",
            website: website ?? "",
            telephone: telephone ?? "",
            telephone_2: telephone_2 ?? "",
            about_me: about_me ?? "",
            bank_account: bank_account ?? "",
            tax_ID: tax_ID ?? "",
            company_logo: company_logo ?? "",
            company_name: company_name ?? "",
          });
          setPmSubscription(subscription?.plan_id);
        }
      })
      .catch((error) => {
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          Logout(navigate);
        }
      })
      .finally(() => {
        setLoader(false);
      });
    AdminService.GetPropertyListByPmId(pmId)
      .then((resp) => {
        if (resp.success) {
          setPropertyList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=", error);
      });
    AdminService.GetSubscriptionList()
      .then((resp) => {
        console.log("0000000000000000000000", resp)
        if (resp.success) {
          setSubscriptionList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=", error);
      });
  }, []);

  const handleSubscriptionPlanChange = () => {
    if (subscription != null) {
      const passData = {
        subscription_plan_id: subscription
      }
      AdminService.UpdatePmSubscriptionPlan(pmId, passData)
        .then((resp) => {
          console.log("response = ", resp);
          if (resp.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `Subscription plan has been updated successfully.`,
            }).finally(function () {
              window.location.reload();
            });
          }
        })
        .catch((error) => {
          console.log("error in change subsciption plan for property maanger = ", error);
        })
        .finally(() => {
          setShowConfirmationModalForPlan(false);
        })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace null values with empty strings
    const sanitizedFormData = Object.fromEntries(
      Object.entries(formData)
    );
    if (logo != "") {
      sanitizedFormData.image = logo;
    }
    sanitizedFormData.lat = formData.lat_;
    sanitizedFormData.long = formData.long_;
    if(companyLogo != ""){
      sanitizedFormData.company_logo = companyLogo;
    }

    // Filter out entries with null or empty string values
    const filteredFormData = Object.fromEntries(
      Object.entries(sanitizedFormData).filter(
        ([key, value]) =>
          key !== "profile_image" &&
          key !== "lat_" &&
          key !== "long_"
      )
    );

    // Validation function
    const validateFormData = (data) => {
      const errors = {};
      if (!data.first_name) errors.first_name = "First name is required";
      if (!data.last_name) errors.last_name = "Last name is required";
      if (!data.address) errors.address = "Address is required";
      if (!data.city) errors.city = "City is required";
      if (!data.state) errors.state = "State is required";
      if (!data.zip) errors.zip = "ZIP is required";
      if (!data.telephone) errors.telephone = "Telephone is required";
      if (!data.about_me) errors.about_me = "About me is required";
      if (!data.bank_account) errors.bank_account = "Bank account number is required";
      if (!data.tax_ID) errors.tax_ID = "Tax ID is required";

      return {
        isValid: Object.keys(errors).length === 0,
        errors
      };
    };

     const {
       isValid,
       errors
     } = validateFormData(filteredFormData);

     if (!isValid) {
       // Show error messages using Swal or any other method
       Swal.fire({
         icon: "error",
         title: "Validation Error",
         html: Object.values(errors).map(error => `<p>${error}</p>`).join(''),
       });
       return;
    }
    
    console.log("filteredFormData = ", filteredFormData);
    AdminService.UpdatePropertyManager(pmId, filteredFormData)
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Details updated successfully!",
          }).finally(function () {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          Logout(navigate);
        }
        if (error?.response?.data?.message?.length > 0) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message,
          });
        }
      });
  };

  const handleSubmitPasswordChange = (e) => {
    e.preventDefault();
    let error = true;
    const errorObj = { ...errorsObj };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!newPassword.trim()) {
      errorObj.newPassword = "New Password is required";
      error = false;
    } else if (!passwordRegex.test(newPassword)) {
      errorObj.newPassword =
        "New Password must be at least 8 characters long with one uppercase and one lowercase letter";
      error = false;
    }
    if (!confirmPassword.trim()) {
      errorObj.confirmPassword = "Confirm Password is required";
      error = false;
    } else if (confirmPassword !== newPassword) {
      errorObj.confirmPassword = "Confirm Password is not same as password.";
      error = false;
    }
    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      password: newPassword,
      confirm_password: confirmPassword,
    };
    AdminService.UpdatePropertyManagerPassword(pmId, passData)
      .then((resp) => {
        console.log("response = ", resp);
        if (resp.success) {
          setOpenPasswordModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password has been changed successfully.",
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  };
  return loader ? <Loader /> : (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              {/* <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div> */}
              <div className="profile-info">
                <div className="profile-photo" style={{ marginTop: "unset" }}>
                  <img
                    src={
                      formData.profile_image !== ""
                        ? formData.profile_image
                        : profile
                    }
                    alt="profile"
                    style={{
                      height: "80px",
                      width: "80px", // Ensure the width is equal to the height
                      borderRadius: "50%" // Make the image circular
                    }}
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {formData.first_name + " " + formData.last_name}
                    </h4>
                    <p>Property Manager</p>
                  </div>
                  <div className="profile-photo" style={{ marginTop: "unset" }}>
                    <img
                      src={
                        formData.company_logo && formData.company_logo !== ""
                          ? formData.company_logo
                          : profile
                      }
                      alt="profile"
                      style={{
                        height: "80px",
                        width: "80px", // Ensure the width is equal to the height
                        borderRadius: "50%" // Make the image circular
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    {subscriptionList.length > 0 && (
                      <select
                        name="cars"
                        className="form-select form-control-lg rounded"
                        value={pmSubscription}
                        style={{
                          cursor: "pointer",
                          width: "fit-content",
                          fontSize: "14px",
                          backgroundColor: "var(--primary)",
                          color: "white"
                        }}
                        onChange={(e) => handleSubscriptionChange(e)}
                      >
                        {subscriptionList.map((item, index) => (
                          <option value={item.id} key={index}>{item.plan}</option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    className="btn btn-primary rounded light"
                    onClick={() => {
                      setOpenPasswordModal(true);
                      setNewPassword("");
                      setConfirmPassword("");
                    }}
                  >
                    Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="settings-form">
                    <h4 className="text-primary">Account Setting</h4>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">First Name</label>
                          <span class="text-danger">*</span>
                          <input
                            placeholder="Enter first name"
                            className="form-control"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Last Name</label>
                          <span class="text-danger">*</span>
                          <input
                            placeholder="Enter last name"
                            className="form-control"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Email</label>
                          <span class="text-danger">*</span>
                          <input
                            placeholder="Enter email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(event) => handleFileChange(event, 'profile_image')}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Company Name</label>
                          <input
                            placeholder="Enter company name"
                            className="form-control"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Company Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(event) => handleFileChange(event, 'company_logo')}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Address</label>
                        <span class="text-danger">*</span>
                        <input
                          type="text"
                          placeholder="Enter complete address"
                          className="form-control"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Latitude (Coordinates)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude for map location"
                            name="lat_"
                            value={formData.lat_}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Longitude (Coordinates)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Longitude for map location"
                            name="long_"
                            value={formData.long_}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">City</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">State</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            id="inputState"
                            placeholder="Enter state"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Zip</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter zip code"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Facebook</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your facebook link"
                            name="fb_link"
                            value={formData.fb_link}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Instagram</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your instagram link"
                            name="inst_link"
                            value={formData.inst_link}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Website</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Telephone 1</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your phone number"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Telephone 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your phone number"
                            name="telephone_2"
                            value={formData.telephone_2}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">About me</label>
                          <span class="text-danger">*</span>
                          <textarea
                            className="form-control"
                            placeholder="Enter about your self"
                            rows="3"
                            name="about_me"
                            value={formData.about_me}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <h4 className="text-primary">Bank Setting</h4>
                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">Account Number</label>
                          <span class="text-danger">*</span>
                          <input
                            className="form-control"
                            placeholder="Enter bank account number"
                            rows="3"
                            name="bank_account"
                            value={formData.bank_account}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">Tax ID</label>
                          <span class="text-danger">*</span>
                          <input
                            className="form-control"
                            placeholder="Enter tax ID"
                            rows="3"
                            name="tax_ID"
                            value={formData.tax_ID}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3"></div>
                      <button className="btn btn-primary" type="submit">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="fade"
        show={openPasswordModal}
        onHide={() => setOpenPasswordModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setOpenPasswordModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => { }}>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">New Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setErrors({
                        ...errors,
                        newPassword: "",
                      });
                    }}
                  />
                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Confirm Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors({
                        ...errors,
                        confirmPassword: "",
                      });
                    }}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger fs-12">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenPasswordModal(false)} variant="danger">
            {" "}
            No{" "}
          </Button>
          <Button onClick={handleSubmitPasswordChange} variant="success"> Save </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="col-lg-12">
          <h4
            className="text-primary mb-4 card p-3"
            style={{ height: "unset" }}
          >
            Property Listing
          </h4>
          <Tab.Container defaultActiveKey={"Grid"}>
            <Tab.Content>
              <Tab.Pane eventKey={"Grid"}>
                <div className="row">
                  {propertyList.length > 0 ? (
                    propertyList.map((item, ind) => (
                      <div
                        className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                        key={ind}
                      >
                        <Link to={`../property-details/${btoa(item.id)}`}>
                          <div className="property-card style-1">
                            {item.option === "slider" ? (
                              <DemoSlider />
                            ) : (
                              <div className="dz-media">
                                <ul>
                                  <li class={`badge badge-sm badge-${item.status == 1 ? 'success' : 'danger'} light`}>
                                    {item.status == 1 ? 'Active' : 'Inactive'}
                                  </li>
                                </ul>
                                <img
                                  src={item?.photo[0]?.url}
                                  alt="image"
                                  style={{ height: "250px" }}
                                />
                              </div>
                            )}
                            <div
                              className="dz-content"
                            >
                              <div className="dz-footer">
                                <h6 className="title text-truncate pb-2" style={{ fontWeight: 400 }}>{item.name}</h6>
                              </div>
                              {/* <div className="dz-footer">
                            <div className="dz-meta mb-0">
                              <span className="badge badge-primary">
                                0 sub services
                              </span>
                            </div>
                          </div> */}
                              <hr />
                              <div className="dz-footer">
                                {/* <div className="property-card">
                                  <div className="property-media">
                                    <img src={item?.pm_detail?.profile_image} alt="image" />
                                  </div>
                                  <h6 className="title mb-0">{item.pm_detail.first_name + ' ' + item.pm_detail.last_name}</h6>
                                </div> */}
                                <h6 className="title mb-0">€22000</h6>
                                <span className="badge badge-primary">
                                  {item?.added_services ? item?.added_services : 0} services
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>No properties found.</div>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <Modal show={showConfirmationModalForPlan} onHide={() => setShowConfirmationModalForPlan(false)}>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowConfirmationModalForPlan(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            Want to update subscription plan for this property manager?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowConfirmationModalForPlan(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={handleSubscriptionPlanChange} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PmProfile;
