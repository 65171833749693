import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

import AdminService from "../../../services/AdminService";
import Swal from "sweetalert2";
import Loader from "../Loader";
import Select from 'react-select';
import ModalLoader from "../Loader/ModalLoader";

const SubService = () => {
  const { id, name } = useParams();
  const serviceId = id ? atob(id) : "";
  const serviceName = name ? atob(name) : "";
  const [loader, setLoader] = useState(false);
  const [subServiceLoader, setSubServiceLoader] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModalOpen, setConfirmModal] = useState(false);
  const [mainServices, setMainServices] = useState([]);
  const [subServiceId, setSubServiceId] = useState(null);
  const [sercieCatList, setServiceCatList] = useState([]);
  const [subServiceMainServiceId, setSubServiceMainServiceId] = useState({
    value: "",
    label: "",
  });
  const [subServiceCategoryId, setSubServiceCategoryId] = useState({
    value: "",
    label: "",
  });
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [statusOption, setStatusOption] = useState("all");

  const [statusFilter, setStatusFilter] = useState('1');
  const [formData, setFormData] = useState({
    sub_service: "",
    service_title: "",
    desc: "",
    price: "",
    status: {
      value: "1",
      label: "Active",
    }
  });


  const Status = [
    {
      value: "1",
      label: "Active",
    },
    {
      value: "0",
      label: "Inactive",
    },
  ];

  const [availableAt, setAvailableAt] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const errorsObj = {
    sub_service: "",
    service_title: "",
    desc: "",
    price: "",
    image: "",
    subServiceMainServiceId: ""
  };

  const [errors, setErrors] = useState(errorsObj);

  const ThreeDot = () => {
    return (
      <>
        {" "}
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    );
  };

  const location = useLocation();
  const [subServices, setSubServices] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  }

  const handleStatusChange = (e, name) => {
    const { value, label } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value, label }
    }))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setErrors({
        ...errors,
        image: "",
      });
    }
  };

  const handleDeleteSubService = (e) => {
    e.preventDefault();
    if (subServiceId != null) {
      AdminService.DeleteSubService(subServiceId)
        .then((resp) => {
          setConfirmModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Service has been deleted successfully.",
          }).finally(function () {
            window.location.reload();
          });
        })
        .catch((error) => {
          console.log("error in delete service = ", error);
        });
    }
  };

  const handleAddSubService = () => {
    console.log("availableAt=  ", availableAt)
    let error = true;
    const errorObj = { ...errorsObj };

    if (subServiceMainServiceId.value == "") {
      errorObj.subServiceMainServiceId = "Main service is required";
      error = false;
    }
    if (subServiceCategoryId.value == "") {
      errorObj.sub_service = "Service category is required";
      error = false;
    }
    if (formData.service_title.trim() === "") {
      errorObj.service_title = "Service Title is required";
      error = false;
    }
    if (formData.desc.trim() === "") {
      errorObj.desc = "Service Description is required";
      error = false;
    }
    if (formData.price.trim() === "") {
      errorObj.price = "Service Price is required";
      error = false;
    }
    if (image === null) {
      errorObj.image = "Service Image is required";
      error = false;
    }
    if (availableAt.length <= 0) {
      errorObj.available_at = "Please select atleast one option.";
      error = false;
    }

    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      service_id: subServiceMainServiceId?.value,
      service_category_id: subServiceCategoryId?.value,
      service_title: formData.service_title,
      desc: formData.desc,
      price: formData.price,
      status: formData.status.value,
      image: image,
      available_at: availableAt.toString()
    }
    console.log("passData = ", passData)
    setLoader(true);
    AdminService.AddSubService(passData)
      .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          setAddModalOpen(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Service has been added successfully.",
          }).finally(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error in add service = ", error);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong! Please try again later.",
        });
      })
      .finally(function () {
        setLoader(false);
      })
  }

  const handleEditSubService = () => {
    console.log("availableAt=  ", availableAt)
    let error = true;
    const errorObj = { ...errorsObj };

    if (subServiceCategoryId.value == "") {
      errorObj.sub_service = "Service category is required";
      error = false;
    }
    if (formData.service_title.trim() === "") {
      errorObj.service_title = "Service Title is required";
      error = false;
    }
    if (formData.desc.trim() === "") {
      errorObj.desc = "Service Description is required";
      error = false;
    }
    if (formData.price.trim() === "") {
      errorObj.price = "Service Price is required";
      error = false;
    }
    if (availableAt.length <= 0) {
      errorObj.available_at = "Please select atleast one option.";
      error = false;
    }

    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      service_id: subServiceMainServiceId?.value,
      service_category_id: subServiceCategoryId?.value,
      service_title: formData.service_title,
      desc: formData.desc,
      price: formData.price,
      status: formData.status.value,
      available_at: availableAt.toString()
    }
    if (image != null) {
      passData.image = image
    }
    console.log("formData = ", formData);
    console.log("passData = ", passData);
    setLoader(true);
    AdminService.UpdateSubService(passData, subServiceId)
      .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          setShowEditModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Service has been updated successfully.",
          }).finally(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error in add service = ", error);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong! Please try again later.",
        }).finally(function () {
          setAddModalOpen(false);
        });
      })
      .finally(function () {
        setLoader(false);
      })
  }

  const clearFeilds = () => {
    setFormData({
      sub_service: "",
      service_title: "",
      desc: "",
      price: "",
      status: {
        value: "1",
        label: "Active",
      }
    });
    setErrors({});
    setImage(null);
    setAvailableAt([]);
    setSelectAll(false);
  }

  const setFeilds = (item) => {
    console.log("item = ", item)
    setFormData({
      service_title: item.service_title ?? "",
      desc: item.desc_ ?? "",
      price: item.price ?? "",
      status: {
        value: item.status,
        label: item.status == 1 ? "Active" : "Inactive",
      }
    })
    setSubServiceMainServiceId({
      value: item?.service_id,
      label: item?.service,
    });
    fetchServiceCategoryList(item?.service_id, statusFilter);
    setSubServiceCategoryId({
      value: item?.service_category_id,
      label: item?.service_category,
    });
    setAvailableAt(item?.available_at);
    if (item?.available_at.length == 3) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }

  const handleFilter = (e) => {
    setSubServices([]);
    setSubServiceLoader(true);
    console.log("-=-=-", e.target.value);
    setStatusOption(e.target.value);
    fetchSubServiceList(serviceId ? serviceId : 'all', e.target.value);
  }

  const fetchSubServiceList = (iid, option) => {
    AdminService.GetAllSubServices(iid, option)
      .then((resp) => {
        if (resp.success) {
          setSubServices(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error in get sub service = ", error);
      })
      .finally(function () {
        setLoader(false);
        setSubServiceLoader(false);
      });
  }

  const fetchServiceCategoryList = async (serviceIdFilterValue, statusFilterValue) => {
    // setLoader(true);
    setServiceCatList([]);
    await AdminService.GetAllServicesCategory(serviceIdFilterValue, statusFilterValue)
      .then((resp) => {
        console.log("resp", resp)
        if (resp.success) {
          const options = resp?.data?.list.map(item => ({
            value: item.id,
            label: item.category
          }));
          setServiceCatList(options);
        }
      })
      .catch((error) => {
        console.log("error in fetch service category list", error);
      })
      .finally(function () {
        // setLoader(false);
      });
  }

  const handleAvailableAtChange = (e) => {
    setErrors({
      ...errors,
      available_at: "",
    });
    const { value, checked } = e.target;
    if (value === "For All") {
      setSelectAll(checked);
      setAvailableAt(checked ? ["check_in", "check_out", "while_stay"] : []);
    } else {
      if (checked) {
        const newAvailableAt = [...availableAt, value];
        setAvailableAt(newAvailableAt);
        if (newAvailableAt.length === 3) {
          setSelectAll(true);
        }
      } else {
        const newAvailableAt = availableAt.filter((item) => item !== value);
        setAvailableAt(newAvailableAt);
        setSelectAll(false);
      }
    }
  };

  useEffect(() => {
    setLoader(true);
    if (!isNaN(serviceId) && serviceId.trim() !== "") {
      document
        .getElementsByClassName("add-sub-service-btn")[0]
        .classList.add("d-none");
      fetchSubServiceList(serviceId, 'all')
    } else {
      if (
        document
          .getElementsByClassName("add-sub-service-btn")[0]
          ?.classList.contains("d-none")
      ) {
        document
          .getElementsByClassName("add-sub-service-btn")[0]
          .classList.remove("d-none");
      }
      // setSubServices(listBlog);
      fetchSubServiceList('all', 'all');
    }

    AdminService.GetMainServices()
      .then((resp) => {
        if (resp.success) {
          const formattedServices = resp?.data?.list?.map((service) => ({
            value: service?.id,
            label: service?.service,
          }));
          setMainServices(formattedServices);
        }
      })
      .catch((error) => {
        console.log("error GetMainServices=-=-=-=-=-=-=-=-=-=-=-", error);
      });
  }, [location]);

  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Services</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">{serviceName ? serviceName : "Services List"}</Link>
            </li>
          </ol>
        </div>
        <select
          name="cars"
          className="form-select form-control-lg rounded"
          value={statusOption}
          style={{
            cursor: "pointer",
            width: "fit-content",
            fontSize: "14px",
            backgroundColor: "var(--primary)",
            color: "white",
            marginRight: "10px"
          }}
          onChange={(e) => handleFilter(e)}
        >
          <option value="all">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <Button
          to="/sub-services"
          className="btn btn-primary rounded light me-3 add-sub-service-btn"
          onClick={() => {
            setAddModalOpen(true);
            clearFeilds();
          }}
        >
          Add Service
        </Button>
      </div>

      <div className="col-xl-12">
        <div className="table-responsive table-hover fs-14 pm-list-table">
          <div id="orderList" className="dataTables_wrapper no-footer">
            <table
              className="table display table-hover display dataTablesCard card-table dataTable no-footer"
              id="example5"
            >
              <thead>
                <tr role="row">
                  <th>No</th>
                  <th>Image</th>
                  <th>Main Service</th>
                  <th>Category</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Available At</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  subServiceLoader ? (
                    <tr>
                      <td colSpan="7">
                        <ModalLoader />
                      </td>
                    </tr>
                  ) :
                    subServices.length > 0 ? (
                      subServices.map((item, ind) => (
                        <tr role="row" key={ind}>
                          <td>{ind + 1}</td>
                          <td>
                            {" "}
                            <img
                              className="fix-height-50 rounded-circle"
                              src={item.image}
                              alt="icon"
                            />
                          </td>
                          <td>{item?.service ? item?.service : "-"}</td>
                          <td>{item?.service_category ? item?.service_category : "-"}</td>
                          <td>{item?.service_title ? item?.service_title : "-"}</td>
                          <td className="text-truncate">{item?.desc_ ? (item?.desc_.length > 25 ? `${item?.desc_.substring(0, 25)}...` : item?.desc_) : "-"}</td>
                          <td>
                            {item?.available_at.includes('check_in') && (<span className="badge badge-info me-1">
                              Check In
                            </span>)}
                            {item?.available_at.includes('while_stay') && (<span className="badge bg-light text-dark me-1">
                              While Stay
                            </span>)}
                            {item?.available_at.includes('check_out') && (<span className="badge bg-dark">
                              Check Out
                            </span>)}
                          </td>
                          <td>{item?.price ? "€" + item?.price : "-"}</td>
                          <td>{item?.status == 1 ? <span className="badge badge-success">
                            Active
                          </span> : <span className="badge badge-danger">
                            Inactive
                          </span>}</td>
                          <td>
                            {/* <Dropdown className="ms-auto">
                              <Dropdown.Toggle
                                variant=""
                                className="btn-link i-false"
                              >
                                <ThreeDot />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item
                                  className="text-black"
                                  onClick={() => {
                                    setShowEditModal(true);
                                    setFeilds(item);
                                    setSubServiceId(item.id);
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="text-black"
                                  onClick={() => {
                                    setConfirmModal(true);
                                    setSubServiceId(item.id);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                            <Button
                              onClick={() => {
                                setShowEditModal(true);
                                setFeilds(item);
                                setSubServiceId(item.id);
                              }}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr role="row">
                        <td colSpan="8" className="text-center">
                          No sub-services available
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </div >

      {/* Add service modal */}
      < Modal className="fade" show={addModalOpen} onHide={() => { clearFeilds(); setAddModalOpen(false) }} centered >
        <Modal.Header>
          <Modal.Title>Add Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => { clearFeilds(); setAddModalOpen(false) }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Main Service</label>
                <div>
                  <Select
                    options={mainServices}
                    className="custom-react-select"
                    isSearchable={false}
                    value={subServiceMainServiceId}
                    onChange={(e) => {
                      setSubServiceMainServiceId(e);
                      setErrors({
                        ...errors,
                        subServiceMainServiceId: "",
                      });
                      fetchServiceCategoryList(e.value, statusFilter);
                    }}
                  />
                  {errors.subServiceMainServiceId && (
                    <div className="text-danger fs-12">
                      {errors.subServiceMainServiceId}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Category</label>
                <div>
                  <Select
                    options={sercieCatList}
                    noOptionsMessage={() => "No options available"}
                    className="custom-react-select"
                    isSearchable={false}
                    onChange={(e) => {
                      setSubServiceCategoryId(e);
                      setErrors({
                        ...errors,
                        sub_service: "",
                      });
                    }}
                  />
                  {errors.sub_service && (
                    <div className="text-danger fs-12">
                      {errors.sub_service}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Title</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Service Title"
                    name="service_title"
                    value={formData.service_title}
                    onChange={handleChange}
                  />
                  {errors.service_title && (
                    <div className="text-danger fs-12">
                      {errors.service_title}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Status</label>
                <Select
                  options={Status}
                  className="custom-react-select"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleStatusChange(e, "status")}
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Price</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Service Price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                  />
                  {errors.price && (
                    <div className="text-danger fs-12">
                      {errors.price}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                  />
                  {errors.image && (
                    <div className="text-danger fs-12">
                      {errors.image}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-12">
                <label className="form-label">Description</label>
                <div>
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter Service Description"
                    name="desc"
                    value={formData.desc}
                    onChange={handleChange}
                  />
                  {errors.desc && (
                    <div className="text-danger fs-12">
                      {errors.desc}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-12">
                <Form.Group>
                  <Form.Label>Available at</Form.Label>
                  <div className="d-flex w-full justify-content-between">
                    <Form.Check
                      type="checkbox"
                      label="For All"
                      value="For All"
                      id="forAll"
                      checked={selectAll}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Check In"
                      value="check_in"
                      id="checkin"
                      checked={availableAt.includes("check_in")}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Check Out"
                      value="check_out"
                      id="checkout"
                      checked={availableAt.includes("check_out")}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="While Stay"
                      value="while_stay"
                      id="whileStay"
                      checked={availableAt.includes("while_stay")}
                      onChange={handleAvailableAtChange}
                    />
                  </div>
                  {errors.available_at && (
                    <div className="text-danger fs-12">
                      {errors.available_at}
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { clearFeilds(); setAddModalOpen(false) }} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={(e) => { e.preventDefault(); handleAddSubService() }} variant="success"> Add </Button>
        </Modal.Footer>
      </Modal >

      {/* Edit service modal */}
      < Modal className="fade" show={showEditModal} onHide={() => { clearFeilds(); setShowEditModal(false) }} centered >
        <Modal.Header>
          <Modal.Title>Edit Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => { clearFeilds(); setShowEditModal(false) }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Main Service</label>
                <div>
                  <Select
                    options={mainServices}
                    className="custom-react-select"
                    isSearchable={false}
                    value={subServiceMainServiceId}
                    onChange={(e) => {
                      setSubServiceMainServiceId(e);
                      setErrors({
                        ...errors,
                        subServiceMainServiceId: "",
                      });
                      fetchServiceCategoryList(e.value, statusFilter);
                    }}
                  />
                  {errors.subServiceMainServiceId && (
                    <div className="text-danger fs-12">
                      {errors.subServiceMainServiceId}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Category</label>
                <div>
                  <Select
                    options={sercieCatList}
                    value={subServiceCategoryId}
                    noOptionsMessage={() => "No options available"}
                    className="custom-react-select"
                    isSearchable={false}
                    onChange={(e) => {
                      setSubServiceCategoryId(e);
                      setErrors({
                        ...errors,
                        sub_service: "",
                      });
                    }}
                  />
                  {errors.sub_service && (
                    <div className="text-danger fs-12">
                      {errors.sub_service}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Title</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Service Title"
                    name="service_title"
                    value={formData.service_title}
                    onChange={handleChange}
                  />
                  {errors.service_title && (
                    <div className="text-danger fs-12">
                      {errors.service_title}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Status</label>
                <Select
                  options={Status}
                  className="custom-react-select"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleStatusChange(e, "status")}
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Price</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Service Price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                  />
                  {errors.price && (
                    <div className="text-danger fs-12">
                      {errors.price}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                  />
                  {errors.image && (
                    <div className="text-danger fs-12">
                      {errors.image}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-12">
                <label className="form-label">Description</label>
                <div>
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter Service Description"
                    name="desc"
                    value={formData.desc}
                    onChange={handleChange}
                  />
                  {errors.desc && (
                    <div className="text-danger fs-12">
                      {errors.desc}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-12">
                <Form.Group>
                  <Form.Label>Available at</Form.Label>
                  <div className="d-flex w-full justify-content-between">
                    <Form.Check
                      type="checkbox"
                      label="For All"
                      value="For All"
                      id="forAll"
                      checked={selectAll}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Check In"
                      value="check_in"
                      id="checkin"
                      checked={availableAt.includes("check_in")}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Check Out"
                      value="check_out"
                      id="checkout"
                      checked={availableAt.includes("check_out")}
                      onChange={handleAvailableAtChange}
                    />
                    <Form.Check
                      type="checkbox"
                      label="While Stay"
                      value="while_stay"
                      id="whileStay"
                      checked={availableAt.includes("while_stay")}
                      onChange={handleAvailableAtChange}
                    />
                  </div>
                  {errors.available_at && (
                    <div className="text-danger fs-12">
                      {errors.available_at}
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { clearFeilds(); setShowEditModal(false) }} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={(e) => { e.preventDefault(); handleEditSubService() }} variant="success"> Save </Button>
        </Modal.Footer>
      </Modal >

      {/* delete service modal */}
      < Modal
        className="fade"
        show={confirmModalOpen}
        onHide={() => setConfirmModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setConfirmModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete this service ? </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setConfirmModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={handleDeleteSubService} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default SubService;
