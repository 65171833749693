import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
import "rsuite/dist/rsuite.min.css";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";
import DemoSlider from "./DemoSlider";
import AdminService from "../../../services/AdminService";
import avat1 from "../../../images/avatar/1.jpg";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";

const PropertyList = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [propertyLoader, setPropertyLoader] = useState(false);
  const [statusOption, setStatusOption] = useState("all");

  const handleFilter = (e) => {
    setPropertyList([]);
    setPropertyLoader(true);
    console.log("-=-=-", e.target.value);
    setStatusOption(e.target.value)
    fetchPropertyList(e.target.value);
  }

  const fetchPropertyList = (option) => {
    AdminService.GetPropertyList(option)
      .then((resp) => {
        console.log("-=-=-", resp);
        if (resp.success) {
          setPropertyList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=", error);
      })
      .finally(() => {
        setLoader(false);
        setPropertyLoader(false);
      });
  }

  useEffect(() => {
    setLoader(true);
    fetchPropertyList('all');
  }, []);

  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Property </h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-list">Property List</Link>
            </li>
          </ol>
        </div>
        <select
          name="cars"
          className="form-select form-control-lg rounded"
          value={statusOption}
          style={{
            cursor: "pointer",
            width: "fit-content",
            fontSize: "14px",
            backgroundColor: "var(--primary)",
            color: "white",
            marginRight: "10px"
          }}
          onChange={(e) => handleFilter(e)}
        >
          <option value="all">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>

      <Tab.Container defaultActiveKey={"Grid"}>
        <Tab.Content>
          <Tab.Pane eventKey={"Grid"}>
            <div className="row">
              {
                propertyLoader ? (
                  <div><ModalLoader /></div>
                ) :
                  propertyList.length > 0 ? (
                    propertyList.map((item, ind) => (
                      <div
                        className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                        key={ind}
                      >
                        <Link to={`./../property-details/${btoa(item?.id)}`}>
                          <div className="property-card style-1">
                            {item.option === "slider" ? (
                              <DemoSlider />
                            ) : (
                              <div className="dz-media">
                                <ul>
                                  <li class={`badge badge-sm badge-${item.status == 1 ? 'success' : 'danger'} light`}>
                                    {item.status == 1 ? 'Active' : 'Inactive'}
                                  </li>
                                </ul>
                                <img
                                  src={item?.photo[0]?.url}
                                  alt="image"
                                  style={{ height: "250px" }}
                                />
                              </div>
                            )}
                            <div
                              className="dz-content pb-4"
                            >
                              <div className="dz-footer">
                                <h6 className="title text-truncate pb-2" style={{ fontWeight: 400 }}>{item.name}</h6>
                              </div>
                              {/* <div className="dz-footer">
                            <div className="dz-meta mb-0">
                              <span className="badge badge-primary">
                                0 sub services
                              </span>
                            </div>
                          </div> */}
                              <hr />
                              <div className="dz-footer">
                                <Link to={`./../property-managers/${btoa(item?.pm_detail?.id)}`} className="property-card-link">
                                  <div className="property-card">
                                    <div className="property-media">
                                      <img src={item?.pm_detail?.profile_image} alt="image" />
                                    </div>
                                    <h6 className="title mb-0 pm-detail-name">{item?.pm_detail?.first_name + ' ' + item?.pm_detail?.last_name}</h6>
                                  </div>
                                </Link>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <span className="badge badge-primary">
                                    {item?.added_services ? item?.added_services : 0} services
                                  </span>
                                  <h6 className="title mb-0 mt-2">Revenue : €{item?.revenue}</h6>
                                   <h6 className="title mb-0 mt-2">Service Fee : €{item.service_fee}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>No properties found.</div>
                  )}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default PropertyList;
