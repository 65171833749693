import Swal from "sweetalert2";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";

export function signupAction(data, navigate) {
  return (dispatch) => {
    signUp(data)
      .then((response) => {
        if (response?.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Registered successfully now please login with email and password!",
          }).then(function () {
            navigate("/login");
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        // const errorMessage = formatError("EMAIL_EXISTS");
        if (error?.response?.data?.message == "Email already exists") {
          const errorMessage = formatError("EMAIL_EXISTS");
          dispatch(signupFailedAction(errorMessage));
        } else {
          const errorMessage = formatError("SOMETHING_WENT_WRONG");
          dispatch(signupFailedAction(errorMessage));
        }
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("nekot");
  navigate("/login");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, rememberMe, navigate) {
  return (dispatch) => {
    login(email, password, rememberMe)
      .then((response) => {
        console.log("response ======================", response);
        console.log("response.data ======================", response.user);
        saveTokenInLocalStorage(response.user);
        runLogoutTimer(dispatch, 3600000000 * 1000, navigate);
        dispatch(
          loginConfirmedAction({ ...response.user, idToken: response.token })
        );
        localStorage.setItem("nekot", response.token);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log("==================", error);
        const errorMessage = formatError("INVALID_PASSWORD");
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
