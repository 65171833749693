import React, { Fragment, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

//** Import Image */
import profile from "../../../images/avatar/1.jpg";
import PageTitle from "../../layouts/PageTitle";
import { Button, Dropdown, Modal, Tab } from "react-bootstrap";
import avat1 from "../../../images/avatar/1.jpg";
import { Link } from "react-router-dom";
import service_transport from "../../../images/services/transport/taxi.jpeg";
import service_food from "../../../images/services/food/breakfast.jpeg";
import service_apartment from "../../../images/property/1.jpg";
import service_drinks from "../../../images/services/drinks/drinks.jpeg";
import service_leisure from "../../../images/services/leisure/leisure.jpg";
import service_club from "../../../images/services/night_life/club.jpg";
import service_kit from "../../../images/services/kit/sleep_kit.jpeg";
import service_others from "../../../images/services/others/home_cooking.jpg";

import avat2 from "../../../images/avatar/2.jpeg";
import avat3 from "../../../images/avatar/3.jpeg";
import avat4 from "../../../images/avatar/4.jpeg";

const listBlog = [
  {
    id: 1,
    image: service_transport,
    tag: "",
    service: "Transport",
    price: "30",
    name: "Thomas Djons",
    option: "image",
    profile: avat1,
  },
  {
    id: 2,
    image: service_apartment,
    tag: "Dubai",
    service: "Apartment",
    price: "40",
    name: "Jane Cooper",
    option: "video",
    profile: avat3,
  },
  // {
  //   id: 3,
  //   image: service_leisure,
  //   tag: "London",
  //   service: "Leasure",
  //   price: "30",
  //   name: "Thomas Djons",
  //   option: "image",
  //   profile: avat1,
  // },
  {
    id: 4,
    image: service_kit,
    tag: "France",
    service: "Special Kit",
    price: "30",
    name: "Keanu Repes",
    option: "video",
    profile: avat3,
  },
  {
    id: 5,
    image: service_others,
    tag: "Russia",
    service: "Others",
    price: "40",
    name: "Rio Fernan",
    option: "slider",
    profile: avat4,
  },
];

const SpProfile = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModalOpen, setConfirmModal] = useState(false);
  const ThreeDot = () => {
    return (
      <>
        {" "}
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    );
  };
  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Profile" motherMenu="App" />

        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content ">
                  <div className="cover-photo rounded"></div>
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img
                      src={profile}
                      className="img-fluid rounded-circle"
                      alt="profile"
                    />
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0">John doe</h4>
                      <p>Service Provider</p>
                    </div>
                    <div className="profile-email px-2 pt-2">
                      <h4 className="text-muted mb-0">johndoe@email.com</h4>
                      <p>Email</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <div className="pt-3">
                      <div className="settings-form">
                        <h4 className="text-primary">Account Setting</h4>
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">First Name</label>
                              <input
                                type="email"
                                placeholder="Enter first name"
                                defaultValue="Henry"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">Last Name</label>
                              <input
                                type="email"
                                placeholder="Enter last name"
                                defaultValue="Jr."
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">Email</label>
                              <input
                                type="email"
                                placeholder="Enter email"
                                defaultValue="henry123@email.com"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">Logo</label>
                              <input type="file" className="form-control" />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              placeholder="Enter complete address"
                              className="form-control"
                              defaultValue="Untere Schönbrunngasse 7, 8043 Graz Housing"
                            />
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">
                                Latitude (Coordinates)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Latitude for map location"
                                defaultValue="47.5162° N"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">
                                Longitude (Coordinates)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Longitude for map location"
                                defaultValue="14.5501° E"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter city"
                                defaultValue="Untere Schönbrunngasse"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">State</label>
                              <select
                                className="form-control"
                                id="inputState"
                                defaultValue="option-2"
                              >
                                <option value="option-1">Choose...</option>
                                <option value="option-2">Austria</option>
                                <option value="option-3">Belarus</option>
                                <option value="option-4">Croatia</option>
                              </select>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">Zip</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter zip code"
                                defaultValue="1423"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">Facebook</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your facebook link"
                                defaultValue="www.facebook.com"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">Instagram</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your instagram link"
                                defaultValue="www.instagram.com"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                              <label className="form-label">Website</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your website"
                                defaultValue="www.website.com"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">Telephone 1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your phone number"
                                defaultValue="1234567890"
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="form-label">Telephone 2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your phone number"
                                defaultValue="1234567891"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <label className="form-label">About</label>
                              <textarea
                                className="form-control"
                                placeholder="Enter about your self"
                                rows="3"
                                defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3"></div>
                          <button className="btn btn-primary" type="submit">
                            Save
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h4 className="text-primary mb-4 card p-3" style={{ height: "unset"}}>Services Listing</h4>
            <Tab.Container defaultActiveKey={"List"}>
              <Tab.Content>
                <Tab.Pane eventKey={"List"}>
                  <div className="row">
                    {listBlog.map((item, ind) => (
                      <div
                        className="col-xl-3 col-xxl-3 col-md-4 col-sm-4 col-lg-4 m-b30"
                        key={ind}
                      >
                        <Link to={`./sub-services/${item.id}`}>
                          <div className="property-card style-1 ">
                            <div className="dz-media ">
                              <img
                                className="fix-height-250"
                                src={item.image}
                                alt="/"
                              />
                            </div>
                            <div
                              className="dz-content"
                              style={{ paddingBottom: "5px" }}
                            >
                              <div className="dz-footer">
                                <h5 className="title">{item.service}</h5>
                                <div onClick={(e) => e.preventDefault()}>
                                  <Dropdown
                                    className="ms-auto"
                                    direction="down"
                                  >
                                    <Dropdown.Toggle
                                      variant=""
                                      className="btn-link i-false"
                                    >
                                      <ThreeDot />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-right">
                                      <Dropdown.Item
                                        className="text-black"
                                        onClick={() => setShowEditModal(true)}
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="text-black"
                                        onClick={() => setConfirmModal(true)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="dz-meta">
                                <span className="badge badge-primary">
                                  {" "}
                                  3 sub services
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Fragment>
      {/* Add Service Modal */}
      <Modal className="fade" show={showAddModal} centered>
        <Modal.Header>
          <Modal.Title>Add Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowAddModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-4">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" required="" />
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <input type="file" className="form-control" required="" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowAddModal(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setShowAddModal(false)}> Add </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Service Modal */}
      <Modal className="fade" show={showEditModal} centered>
        <Modal.Header>
          <Modal.Title>Edit Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowEditModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-4">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  required=""
                  value="Transport"
                />
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <input type="file" className="form-control" required="" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowEditModal(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setShowEditModal(false)}> Save </Button>
        </Modal.Footer>
      </Modal>

      {/* delete service modal */}
      <Modal className="fade" show={confirmModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setConfirmModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete this service ? </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setConfirmModal(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setConfirmModal(false)}> Yes </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SpProfile;
