import axios from "axios";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";
import fetch from "../interceptor/fetchInterceptor";

export function signUp(postData) {
  //axios call

  return fetch({
    url: "/auth/register/admin",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function login(email, password, rememberMe) {
  const postData = {
    email,
    password,
    remember_me: rememberMe,
  };
  return fetch({
    url: "/auth/login/admin",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "SOMETHING_WENT_WRONG":
      //return 'Email already exists';
      // swal("Oops", "Email already exists", "error");
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Something went wrong!",
      });
      break;
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      // swal("Oops", "Email already exists", "error");
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Email already exists",
      });
      break;
    case "EMAIL_NOT_FOUND":
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Email not found",
      });
      //return 'Email not found';
      //swal("Oops", "Email not found", "error",{ button: "Try Again!",});
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Invalid email or password",
      });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}
