import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/AppConfig";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("nekot");

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      const navigate = useNavigate();
      navigate("/");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    console.log("response.status = ", response.status);
    return response.data;
  },
  (error) => {
    console.log("response.status123 = ", error.response.status);

    if (error.response.status === 403 || error.response.status === 401) {
      console.log("errorerrorerrorerror111 = ", error);
      localStorage.removeItem("access_token");
      localStorage.removeItem("nekot");
      localStorage.setItem("login", JSON.stringify(false));
      localStorage.removeItem("userDetails");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default service;
