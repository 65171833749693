import React, {
  useContext,
  useState,
  useEffect
} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import AdminService from "../../../services/AdminService";
// Map
import WorldMap from "react-svg-worldmap";

//** Import Image */
import customers1 from "../../../images/customers/1.jpg";
import customers2 from "../../../images/customers/2.jpg";
// import customers3 from "../../../images/customers/3.jpg";

import PropertySlider from "../Omah/Home/Slider/PropertySlider";

// Chart
import ChartDoughnut from "../Omah/Home/Chart/DonutChart";
import { ThemeContext } from "../../../context/ThemeContext";
// Apex Chart
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
const PieChart = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/PieChart"), 500)
);
const ChartTimeLine = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartTimeline"), 500)
);

const ChartTimeLine2 = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartTimeline2"), 500)
);

const ChartTimeLine3 = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartTimeline3"), 500)
);

const ChartTimeLine4 = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartTimeline4"), 500)
);

const ChartTimeLine5 = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartTimeline5"), 500)
);

const ChartBar = loadable(() =>
  pMinDelay(import("../Omah/Home/Chart/ChartBar"), 500)
);

const Star = () => {
  return <i className="las la-star" />;
};

const data = [
  { country: "cn", value: 1389618778 },
  { country: "in", value: 1311559204 },
  { country: "us", value: 331883986 },
  { country: "id", value: 264935824 },
  { country: "pk", value: 210797836 },
  { country: "br", value: 210301591 },
  { country: "ng", value: 208679114 },
  { country: "bd", value: 161062905 },
  { country: "ru", value: 141944641 },
  { country: "mx", value: 127318112 },
  { country: "jp", value: 126476461 },
  { country: "et", value: 118015321 },
  { country: "ph", value: 113850136 },
  { country: "eg", value: 104258327 },
  { country: "vn", value: 97338579 },
  { country: "cd", value: 92377817 },
  { country: "tr", value: 85042700 },
  { country: "ir", value: 83992949 },
  { country: "de", value: 83019213 },
  { country: "th", value: 69950807 },
];

const getStyle = ({ minValue }) => ({
  stroke: "rgb(200, 200, 200)",
  cursor: "pointer",
});

function Home() {
  const { svgMapStyle } = useContext(ThemeContext);
  const [analytics, setAnalytics] = useState({
    total_properties_connected: 0,
    total_services_activated: 0,
    total_earning: 0,
    total_last_year_earning: 0,
    total_booking: 1,
    total_service_booked: 1,
    ongoing_services: 1,
    completed_booking: 1,
    ongoing_booking: 1,
    completed_services: 1,
    total_current_month_revenue: 0,
    total_last_month_revenue: 0,
    total_earning_with_fee: 0,
    total_last_year_earning_with_fee: 0,
  });
  const [revenueWithoutFee, setRevenueWithoutFee] = useState({ monthsArray: [], valuesArray: [] });
  const [revenueWithFee, setRevenueWithFee] = useState({ monthsArray: [], valuesArray: [] });
  const [servicesRevenue, setServicesRevenue] = useState({ serviceIds: [], totals: [] });
  const [pmRevenueList, setPmRevenueList] = useState({ pmList: [], totals: [] });
  const [orderCountList, setOrderCountList] = useState([]);
  const [isChartShow, setIsChartShow] = useState(false);
  const [isChartShow2, setIsChartShow2] = useState(false);
  const [isChartShow3, setIsChartShow3] = useState(false);
  
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const now = new Date();
  const currentMonthIndex = now.getMonth();

  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
  const [selectedMonth2, setSelectedMonth2] = useState(currentMonthIndex);
  const [selectedMonth3, setSelectedMonth3] = useState(currentMonthIndex);
  const [selectedMonth4, setSelectedMonth4] = useState(currentMonthIndex);
  const [selectedMonth5, setSelectedMonth5] = useState(currentMonthIndex);
  const [selectedYear, setSelectedYear] = useState(now.getFullYear());
  const [selectedYear2, setSelectedYear2] = useState(now.getFullYear());
  const [selectedYear3, setSelectedYear3] = useState(now.getFullYear());
  const [selectedYear4, setSelectedYear4] = useState(now.getFullYear());
  const [selectedYear5, setSelectedYear5] = useState(now.getFullYear());

  // Generate all years list
  const allYears = [];
  const initialYear = 2024;

  const currYear = now.getFullYear();

  for (let i = 0; i <= currYear - initialYear; i++) {
    allYears[i] = initialYear + i;
  }

  const handleYearSelection = (e) => {
    setSelectedYear(e.target.value);

    const passData = {
      month: selectedMonth,
      year: e.target.value
    }

    getMonthlyRevenue(passData);
  }

  const handleYear2Selection = (e) => {
    setSelectedYear2(e.target.value);

    const passData = {
      month: selectedMonth,
      year: e.target.value
    }

    setIsChartShow(false);
    fetchTopPMList(passData);
  }

  const fetchMonthlyRevenue = (e) => {
    const passData = {
      month: e.target.value,
      year: selectedYear
    }

    setSelectedMonth(e.target.value);
    getMonthlyRevenue(passData)
  }

  const getMonthlyRevenue = (passData) => {
    AdminService.getMonthlyRevenue(passData)
    .then((resp) => {
      setAnalytics((prevFormData) => ({
        ...prevFormData,
        total_current_month_revenue: resp.data.total_current_month_revenue,
        total_last_month_revenue: resp.data.total_last_month_revenue,
      }));

      setIsChartShow(false);
      fetchTotalEarningsByService(passData);
    })
    .catch((err) => {
      console.log(err)
    });
  }

  const handleMonthSelect3 = (e) => {
    const passData = {
      month: e.target.value,
    };

    setSelectedMonth3(e.target.value);
    fetchTotalRevenue(passData)
  }

  const handleYearSelection4 = (e) => {
    setSelectedYear4(e.target.value);

    const passData = {
      month: 'All',
      year: e.target.value
    }

    setIsChartShow(false);
    fetchTotalRevenue(passData);
    getLastTwelveMonthsRevenueWithoutFee(passData);
  }

  const fetchTotalRevenue = (passData) => {
    AdminService.totalRevenue(passData)
    .then((resp) => {
      if (resp.success) {
        setAnalytics((prev) => ({
          ...prev,
          total_earning: resp.data.total_earning,
          total_last_year_earning: resp.data.total_last_year_earning,
        }));
        setIsChartShow(true);
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  const handleMonthSelect4 = (e) => {
    const passData = {
      month: e.target.value,
    };

    setSelectedMonth4(e.target.value);
    fetchTotalRevenueWithFee(passData)
  }

  const handleYearSelection5 = (e) => {
    setSelectedYear5(e.target.value);

    const passData = {
      month: 'All',
      year: e.target.value
    }

    setIsChartShow(false);
    fetchTotalRevenueWithFee(passData);
    getLastTwelveMonthsRevenueWithFee(passData);
  }

  const fetchTotalRevenueWithFee = (passData) => {
    AdminService.totalRevenueWithFee(passData)
    .then((resp) => {
      if (resp.success) {
        setAnalytics((prev) => ({
          ...prev,
          total_earning_with_fee: resp.data.total_earning_with_fee,
          total_last_year_earning_with_fee: resp.data.total_last_year_earning_with_fee,
        }))
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  const fetchTotalEarningsByService = (passData) => {
    AdminService.totalEarningsByService(passData)
    .then((resp) => {
      if (resp.success) {
        setServicesRevenue(resp.data);
        setIsChartShow(true);
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  const fetchTopPMList = (passData) => {
    AdminService.getTopPMMonthlyRevenue(passData)
    .then((resp) => {
      if (resp.success) {
        // setPmRevenueList(resp.data?.totals);
        setPmRevenueList((prev) => ({
          ...prev,
          pmList: resp.data.pmList,
          totals: resp.data.totals,
        }));
        setIsChartShow(true);

        setAnalytics((prev) => ({
          ...prev,
          current_month_pm_earnings: resp.data.current_month_pm_earnings,
          last_month_pm_earnings: resp.data.last_month_pm_earnings,
        }))
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  const fetchPmMonthlyRevenue = (e) => {
    const passData = {
      month: e.target.value,
      year: selectedYear2
    }

    setSelectedMonth2(passData.month);

    setIsChartShow(false);
    fetchTopPMList(passData);
  }

  const fetchOrderCountList = (e) => {
    const passData = {
      month: e.target.value,
      year: selectedYear3,
    }

    setSelectedMonth5(passData.month);
    setIsChartShow3(false);
    getOrderCountList(passData);
  }

  const handleYearSelection3 = (e) => {
    const passData = {
      month: selectedMonth5,
      year: e.target.value,
    }

    setSelectedYear3(passData.year);
    setIsChartShow3(false);
    getOrderCountList(passData);
  }
  
    useEffect(() => {
    AdminService.getDashboard()
    .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          setAnalytics((prevFormData) => ({
            ...prevFormData,
            total_properties_connected: resp.data.total_properties_connected,
            total_services_activated: resp.data.total_services_activated,
            // total_earning: resp.data.total_earning,
            // total_earning_with_fee: resp.data.total_earning_with_fee,
            // total_last_year_earning: resp.data.total_last_year_earning,
            // total_last_year_earning_with_fee: resp.data.total_last_year_earning_with_fee,
            total_current_month_revenue: resp.data.total_current_month_revenue,
            total_last_month_revenue: resp.data.total_last_month_revenue,
            total_service_booked: resp.data.total_service_booked,
            ongoing_services: resp.data.ongoing_services,
            completed_booking: resp.data.completed_booking,
            ongoing_booking: resp.data.ongoing_booking,
            completed_services: resp.data.completed_services
          }));
        }
      })
      .catch((err) => {
        console.log("err = ", err);
      })
  },[])

  const getLastTwelveMonthsRevenueWithoutFee = (passData) => {
    AdminService.getLastTwelveMonthsRevenueWithoutFee(passData)
    .then((resp) => {
      if (resp.success) {
        setRevenueWithoutFee(resp.data);
        setIsChartShow(true);
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  useEffect(() => {
    const passData = {
      year: selectedYear4
    };

    getLastTwelveMonthsRevenueWithoutFee(passData);
  }, [setIsChartShow, setRevenueWithoutFee])

  const getLastTwelveMonthsRevenueWithFee = (passData) => {
    AdminService.getLastTwelveMonthsRevenueWithFee(passData)
    .then((resp) => {
      if (resp.success) {
        setRevenueWithFee(resp.data);
        setIsChartShow2(true);
      }
    })
    .catch((err) => {
      console.log("err = ", err);
    })
  }

  useEffect(() => {
    const passData = {
      year: selectedYear5
    };

    getLastTwelveMonthsRevenueWithFee(passData);
  }, [setIsChartShow2, setRevenueWithFee])

  useEffect(() => {
    const passData = {
      month: selectedMonth
    }
    fetchTotalEarningsByService(passData);
  }, [setIsChartShow, setServicesRevenue])

  useEffect(() => {
    const passData = {
      month: selectedMonth2
    }

    fetchTopPMList(passData);
  }, [setIsChartShow, setPmRevenueList])

  useEffect(() => {
    const passData = {
      month: 'All',
      year: selectedYear4
    }

    fetchTotalRevenue(passData);
  }, [])

  useEffect(() => {
    const passData = {
      month: selectedMonth4,
      year: selectedYear5
    }

    fetchTotalRevenueWithFee(passData);
  }, [])

  const getOrderCountList = (passData) => {
    AdminService.getOrderStatusCountList(passData)
    .then((resp) => {
      setOrderCountList(resp.data);
      setIsChartShow3(true);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    const passData = {
      month: selectedMonth5,
      year: selectedYear3,
    };

    getOrderCountList(passData);
  }, []);

  return (
    <>
      <div className="form-head d-md-flex mb-sm-4 mb-3 align-items-start">
        <div className="me-auto  d-lg-block">
          <h2 className="text-black font-w600">Dashboard</h2>
          <p className="mb-0">Welcome to Snapin Property Management</p>
        </div>
        <Link to="/" className="btn btn-primary rounded light">
          Refresh
        </Link>
      </div>

      <div className="row">
        <div className="col-xl-6 col-xxl-6">
          <div className="row">
            <div className="col-xl-12 col-md-6">
              <div className="card bg-danger property-bx text-white">
                <div className="card-body">
                  <div className="media d-sm-flex d-block align-items-center">
                    <span className="me-4 d-block mb-sm-0 mb-3">
                      <svg
                        width={80}
                        height={80}
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.8333 79.1667H4.16659C2.33325 79.1667 0.833252 77.6667 0.833252 75.8333V29.8333C0.833252 29 1.16659 28 1.83325 27.5L29.4999 1.66667C30.4999 0.833332 31.8333 0.499999 32.9999 0.999999C34.3333 1.66667 34.9999 2.83333 34.9999 4.16667V76C34.9999 77.6667 33.4999 79.1667 31.8333 79.1667ZM7.33325 72.6667H28.4999V11.6667L7.33325 31.3333V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M75.8333 79.1667H31.6666C29.8333 79.1667 28.3333 77.6667 28.3333 75.8334V36.6667C28.3333 34.8334 29.8333 33.3334 31.6666 33.3334H75.8333C77.6666 33.3334 79.1666 34.8334 79.1666 36.6667V76C79.1666 77.6667 77.6666 79.1667 75.8333 79.1667ZM34.9999 72.6667H72.6666V39.8334H34.9999V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M60.1665 79.1667H47.3332C45.4999 79.1667 43.9999 77.6667 43.9999 75.8334V55.5C43.9999 53.6667 45.4999 52.1667 47.3332 52.1667H60.1665C61.9999 52.1667 63.4999 53.6667 63.4999 55.5V75.8334C63.4999 77.6667 61.9999 79.1667 60.1665 79.1667ZM50.6665 72.6667H56.9999V58.8334H50.6665V72.6667Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body mb-sm-0 mb-3 me-5">
                      <h4 className="fs-22 text-white">
                        Total Properties Connected
                      </h4>
                      <div className="progress mt-3 mb-2" style={{ height: 8 }}>
                        <div
                          className="progress-bar bg-white progress-animated"
                          style={{
                            width: analytics.total_properties_connected + "%",
                            height: 8,
                          }}
                        >
                          <span className="sr-only">86% Complete</span>
                        </div>
                      </div>
                      <span className="fs-14">
                        {/* 431 more to break last month record */}
                      </span>
                    </div>
                    <span className="fs-35 font-w500">
                      {analytics.total_properties_connected}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h3 className="fs-18 text-black">
                    Total Revenue (without fee)
                  </h3>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedYear4}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                    }}
                    onChange={(e) => handleYearSelection4(e)}
                  >
                    {
                      allYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </div>
                <div className="card-body pt-2 pb-0">
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="fs-28 text-black font-w600 me-3">
                      €{analytics?.total_earning}
                    </span>
                    <p className="me-sm-auto me-3 mb-sm-0 mb-3">
                      last year €{analytics?.total_last_year_earning}
                    </p>
                  </div>
                  {isChartShow && (
                    <ChartTimeLine2 revenueWithoutFee={revenueWithoutFee} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h3 className="fs-18 text-black">Total Revenue (Only fee)</h3>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedYear5}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                    }}
                    onChange={(e) => handleYearSelection5(e)}
                  >
                    {
                      allYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </div>
                <div className="card-body pt-2 pb-0">
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="fs-28 text-black font-w600 me-3">
                      €{analytics?.total_earning_with_fee}
                    </span>
                    <p className="me-sm-auto me-3 mb-sm-0 mb-3">
                      last year €{analytics?.total_last_year_earning_with_fee}
                    </p>
                  </div>
                  {isChartShow2 && (
                    <ChartTimeLine3 revenueWithFee={revenueWithFee} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h3 className="fs-18 text-black flex-grow-1">Total Revenue (By PM)</h3>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedMonth2}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onChange={fetchPmMonthlyRevenue}
                  >
                    <option value="All">All</option>
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedYear2}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                    }}
                    onChange={(e) => handleYear2Selection(e)}
                  >
                    {
                      allYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </div>
                <div className="card-body pt-2 pb-0">
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="fs-28 text-black font-w600 me-3">
                      €{analytics?.current_month_pm_earnings}
                    </span>
                    <p className="me-sm-auto me-3 mb-sm-0 mb-3">
                      last month €{analytics?.last_month_pm_earnings}
                    </p>
                  </div>
                  {isChartShow && (
                    <ChartTimeLine4 pmRevenueList={pmRevenueList} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-xxl-6">
          <div className="row">
            <div className="col-xl-12 col-md-6">
              <div className="card bg-danger property-bx text-white">
                <div className="card-body">
                  <div className="media d-sm-flex d-block align-items-center">
                    <span className="me-4 d-block mb-sm-0 mb-3">
                      <svg
                        width={80}
                        height={80}
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.8333 79.1667H4.16659C2.33325 79.1667 0.833252 77.6667 0.833252 75.8333V29.8333C0.833252 29 1.16659 28 1.83325 27.5L29.4999 1.66667C30.4999 0.833332 31.8333 0.499999 32.9999 0.999999C34.3333 1.66667 34.9999 2.83333 34.9999 4.16667V76C34.9999 77.6667 33.4999 79.1667 31.8333 79.1667ZM7.33325 72.6667H28.4999V11.6667L7.33325 31.3333V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M75.8333 79.1667H31.6666C29.8333 79.1667 28.3333 77.6667 28.3333 75.8334V36.6667C28.3333 34.8334 29.8333 33.3334 31.6666 33.3334H75.8333C77.6666 33.3334 79.1666 34.8334 79.1666 36.6667V76C79.1666 77.6667 77.6666 79.1667 75.8333 79.1667ZM34.9999 72.6667H72.6666V39.8334H34.9999V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M60.1665 79.1667H47.3332C45.4999 79.1667 43.9999 77.6667 43.9999 75.8334V55.5C43.9999 53.6667 45.4999 52.1667 47.3332 52.1667H60.1665C61.9999 52.1667 63.4999 53.6667 63.4999 55.5V75.8334C63.4999 77.6667 61.9999 79.1667 60.1665 79.1667ZM50.6665 72.6667H56.9999V58.8334H50.6665V72.6667Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body mb-sm-0 mb-3 me-5">
                      <h4 className="fs-22 text-white">
                        Total Services Activated
                      </h4>
                      <div className="progress mt-3 mb-2" style={{ height: 8 }}>
                        <div
                          className="progress-bar bg-white progress-animated"
                          style={{
                            width: analytics.total_services_activated + "%",
                            height: 8,
                          }}
                        >
                          <span className="sr-only">86% Complete</span>
                        </div>
                      </div>
                      <span className="fs-14">
                        {/* 431 more to break last month record */}
                      </span>
                    </div>
                    <span className="fs-35 font-w500">
                      {analytics.total_services_activated}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h3 className="fs-18 text-black flex-grow-1">Total Revenue By Main Services</h3>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedMonth}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onChange={fetchMonthlyRevenue}
                  >
                    <option value="All">All</option>
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedYear}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                    }}
                    onChange={(e) => handleYearSelection(e)}
                  >
                    {
                      allYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </div>

                <div className="card-body pt-2 pb-0">
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="fs-28 text-black font-w600 me-3">
                      €{analytics.total_current_month_revenue}
                    </span>
                    <p className="me-sm-auto me-3 mb-sm-0 mb-3">
                      last month €{analytics.total_last_month_revenue}
                    </p>
                  </div>
                  {
                    isChartShow && <ChartTimeLine servicesRevenue={servicesRevenue} />
                  }
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h3 className="fs-18 text-black flex-grow-1">Order Status</h3>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedMonth5}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                      marginRight: "10px",
                    }}
                    onChange={fetchOrderCountList}
                  >
                    <option value="All">All</option>
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={selectedYear3}
                    style={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: "14px",
                      backgroundColor: "var(--primary)",
                      color: "white",
                    }}
                    onChange={(e) => handleYearSelection3(e)}
                  >
                    {
                      allYears.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))
                    }
                  </select>
                </div>

                <div className="card-body pt-2 pb-0">
                  {/* <div className="d-flex flex-wrap align-items-center">
                    <span className="fs-28 text-black font-w600 me-3">
                      €{analytics.total_current_month_revenue}
                    </span>
                    <p className="me-sm-auto me-3 mb-sm-0 mb-3">
                      last month €{analytics.total_last_month_revenue}
                    </p>
                  </div> */}
                  {
                    isChartShow3 && <ChartTimeLine5 orderCountList={orderCountList} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
