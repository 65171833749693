import React, { useState } from 'react';
import Select from 'react-select';
import {Link} from 'react-router-dom';
import { Tab, Nav, Collapse, Row, Card, Col, Button, Modal, Badge,Container  } from 'react-bootstrap';
import {RangeSlider} from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

import PageTitle from '../../layouts/PageTitle';

//images
import property1 from '../../../images/property/1.jpg'
import property2 from '../../../images/property/2.jpg'
import property3 from '../../../images/property/3.jpg'
import property4 from '../../../images/property/4.jpg'
import avat1 from '../../../images/avatar/1.jpg';
import avat2 from '../../../images/avatar/2.jpeg';
import avat3 from '../../../images/avatar/3.jpeg';
import avat4 from '../../../images/avatar/4.jpeg';
import DemoSlider from './DemoSlider';

const location = [
    { value: '1', label: 'Select Location' },
    { value: '2', label: 'London' },
    { value: '3', label: 'Paris' },
    { value: '4', label: 'California' },
    { value: '5', label: 'Indonesia' },
    { value: '5', label: 'Iraq' },
]

const type = [
    { value: '1', label: 'Property Type' },
    { value: '2', label: 'Wide Area' },
    { value: '3', label: 'Building' },
    { value: '4', label: 'Commertial' },
    { value: '5', label: 'Residental' },
    
]

const Rent = [
    { value: '1', label: 'Select Category' },
    { value: '2', label: 'Airbnb' },
    { value: '3', label: 'For Sale' },
]

const cardData = [
    {image:property1, tag:'', service:'Transport', price:'30', name:'Thomas Djons' , option:"image" , profile: avat1},
    {image:property2, tag:'Paris', service:'Food', price:'50', name:'Oliver Jean', option:"slider", profile: avat4 },
    {image:property3, tag:'Dubai', service:'Apartment', price:'40',  name:'Jane Cooper' , option:"video", profile: avat3},
    {image:property1, tag:'London', service:'Drinks', price:'60', name:'Monalisa' , option:"image", profile: avat2},
    {image:property2, tag:'London', service:'Leasure', price:'30', name:'Thomas Djons' , option:"image" , profile: avat1},
    {image:property3, tag:'France', service:'Special Kit', price:'30', name:'Keanu Repes' , option:"video", profile: avat3},
    {image:property1, tag:'London', service:'NightLife', price:'60', name:'Monalisa' , option:"image", profile: avat2},
    {image:property2, tag:'Russia', service:'Others', price:'40',  name:'Rio Fernan', option:"slider", profile: avat4 },
];

const listBlog = [
    {image:property1, tag:'', service:'Transport', price:'30', name:'Thomas Djons' , option:"image" , profile: avat1},
    {image:property2, tag:'Paris', service:'Food', price:'50', name:'Oliver Jean', option:"slider", profile: avat4 },
    {image:property3, tag:'Dubai', service:'Apartment', price:'40',  name:'Jane Cooper' , option:"video", profile: avat3},
    {image:property1, tag:'London', service:'Drinks', price:'60', name:'Monalisa' , option:"image", profile: avat2},
    {image:property2, tag:'London', service:'Leasure', price:'30', name:'Thomas Djons' , option:"image" , profile: avat1},
    {image:property3, tag:'France', service:'Special Kit', price:'30', name:'Keanu Repes' , option:"video", profile: avat3},
    {image:property1, tag:'London', service:'NightLife', price:'60', name:'Monalisa' , option:"image", profile: avat2},
    {image:property2, tag:'Russia', service:'Others', price:'40',  name:'Rio Fernan', option:"slider", profile: avat4 },
];

const Rentoption = [
    { value: '1', label: 'Default' },
    { value: '2', label: 'Airbnb' },
    { value: '3', label: 'For Sale' },
]

const PropertyList = () => {
    const [serviceListModal, setServiceListModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [value, setValue] = useState([30.01, 50.01]);
    const [value2, setValue2] = useState([40.01, 60.01]);
    const [openVideo, setOpenVideo] = useState(false);
    const [openMenu, setOpenMenu] = useState(true);
    return (
        <>
            <PageTitle activeMenu={"Services"} motherMenu={"Add Service"} />
            <div className="row">
                <div className="col-12">
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title SlideToolHeader"
                            onClick={()=>setOpenMenu(!openMenu)}
                        >
                            <div className="cpa">
                                <i className="fa-sharp fa-solid fa-filter me-2" />Filter
                            </div>
                            <div className="tools">
                                <Link to={"#"} className={`handle ${ openMenu ? "expand" : "collpase"}`}>
                                    <i className="fal fa-angle-down" />
                                </Link>
                            </div>
                        </div>
                        <Collapse in={openMenu}>                        
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <form>
                                        <div className="row">
                                            <div className="mb-3 col-lg-3 col-md-6">
                                                <input type="text" className="form-control" placeholder="Enter Your Keyword..." required="" />
                                            </div>
                                            {/* <div className="mb-3 col-lg-3 col-md-6">                                            
                                                <Select 
                                                    options={location} 
                                                    defaultValue={location[0]}
                                                    className="custom-react-select"
                                                    isSearchable = {false}
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-3 col-md-6">
                                                <Select 
                                                    options={type} 
                                                    defaultValue={type[0]}
                                                    className="custom-react-select"
                                                    isSearchable = {false}
                                                />
                                                
                                            </div>
                                            <div className="mb-3 col-lg-3 col-md-6">                                            
                                                <Select 
                                                    options={Rent} 
                                                    defaultValue={Rent[0]}
                                                    className="custom-react-select"
                                                    isSearchable = {false}
                                                />
                                            </div> */}
                                            <div className="col-lg-4 col-md-6 align-self-end mb-3">
                                                <button className="btn btn-primary rounded-sm w-100" title="Click here to Search" type="button">
                                                    <i className="fa fa-search me-1" />Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row align-items-center">
                                        {/* <div className="mb-3 col-lg-4 col-md-6">
                                            <h6 className="title">Price Range</h6>
                                            <div className="combined2 mt-3">
                                                <RangeSlider
                                                    max={80}
                                                    step={0.01}
                                                    tooltip = {false}
                                                    className='combined-slider mb-3'
                                                    progress                                                                                         
                                                    value={value}
                                                    onChange={value => {
                                                        setValue(value);
                                                    }}
                                                />                                            
                                                <span className="example-val" id="slider-combined2-value-min">                                                
                                                    {value[0]}
                                                </span>
                                                {" "} to {" "}
                                                <span className="example-val" id="slider-combined2-value-max">
                                                    {value[1]}
                                                </span> 
                                            </div>
                                        </div>
                                        <div className="mb-3 col-lg-4 col-md-6">
                                            <h6 className="title">Area Range</h6>
                                            <div className="combined mt-3">
                                                <RangeSlider
                                                    max={80}
                                                    step={0.01}
                                                    tooltip = {false}
                                                    className='combined-slider mb-3'
                                                    progress                                                                                            
                                                    value={value2}
                                                    onChange={value => {
                                                        setValue2(value);
                                                    }}
                                                />                                            
                                                <span className="example-val" id="slider-combined2-value-min">                                                
                                                    {value2[0]}
                                                </span>
                                                {" "} to {" "}
                                                <span className="example-val" id="slider-combined2-value-max">
                                                    {value2[1]}
                                                </span> 
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>                    
                </div>
            </div>
            <Tab.Container defaultActiveKey={'List'}>
                <div className="my-3 row">
                    <div className="col-sm-6 align-self-center">
                        <p className="mb-0 d-sm-block d-none">
                            Showing 1-9 of 13 Results
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="d-flex align-items-center me-2">
                                <label className="form-label me-2 mb-0">Sort by:</label>                            
                                <Select 
                                    options={Rentoption} 
                                    defaultValue={Rentoption[0]}
                                    className="custom-react-select"
                                    isSearchable = {false}
                                />
                            </div>
                            {/* <Nav as="ul" className="nav nav-pills style-1">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey={'Grid'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey={'List'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav> */}
                        </div>
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey={'List'}>
                        <div className="row justify-content-lg-center">
                            {listBlog.map((data, index) => (
                                
                                <div className="col-xl-6 col-lg-8 m-b30" key={index}>
                                    
                                        <div className="property-card style-1 blog-half">
                                            <div className="dz-media">
                                                <img src={data.image} alt="/" />
                                            </div>
                                            <div className="dz-content">
                                                <div className="dz-footer">
                                                    <h3 className="title">{data.service}</h3>
                                                    <ul>
                                                        <li>
                                                            <h4><span class="badge bg-primary">${data.price}</span></h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have. There are many variations of passages.</p>
                                                <hr />
                                                <div className="dz-footer">
                                                    <div className="property-card">
                                                        <div className="property-media">
                                                            <img src={data.profile} alt="/" />
                                                        </div>
                                                        <h6 className="title mb-0">{data.name}</h6>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <Link to={"#"}  onClick={() => setServiceListModal(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line>
                                                                </svg>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                   
                                </div>
    
                            ))}
						</div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <ModalVideo
				channel="youtube"
                autoplay = {true}
				youtube={{ mute: 0, autoplay: 0 }}
				isOpen={openVideo}
				videoId="XJb1G9iRoL4"
				onClose={() => setOpenVideo(false)} 
            />
            
            {/* <!-- Modal --> */}
            <Modal className="fade" show={serviceListModal}  centered>
                <Modal.Header>
                <Modal.Title>Add Service</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={() => setServiceListModal(false)}
                >
                </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-xl-12 col-xxl-12 col-lg-12">
                        <div className="card border-0 pb-0">
                            <div className="card-body p-0">
                                <div
                                    style={{ height: "auto" }}
                                    id="DZ_W_Todo4"
                                    className="widget-media px-4 dz-scroll"
                                >
                                    <ul className="timeline">
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                                                <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customCheckBox1"
                                                required=""
                                                />
                                                <label
                                                className="form-check-label"
                                                htmlFor="customCheckBox1"
                                                ></label>
                                            </div>
                                            <div className="media-body">
                                                <div className='' style={{ display: "flex" }}>
                                                    <h5 className="mb-0">Taxi</h5>&nbsp;&nbsp;
                                                        &nbsp;
                                                   <h5><strong className='ml-5 text-primary'> $35</strong></h5>
                                                </div>
                                                
                                                <div className="bootstrap-badge pt-sm-1">
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        Airport
                                                        </Badge>
                                                        &nbsp;
                                                        &nbsp;
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        In Side City
                                                    </Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            < div className = "form-check custom-checkbox checkbox-success check-lg me-3" >
                                                <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customCheckBox2"
                                                required=""
                                                />
                                                <label
                                                className="form-check-label"
                                                htmlFor="customCheckBox2"
                                                ></label>
                                            </div>
                                            <div className="media-body">
                                                <div className='' style={{ display: "flex" }}>
                                                    <h5 className="mb-0">Parking</h5>&nbsp;&nbsp;
                                                        &nbsp;
                                                    <h5><strong className='ml-5 text-primary'> $25</strong></h5>
                                                </div>
                                               <div className="bootstrap-badge pt-sm-1">
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        Parallel
                                                        </Badge>
                                                        &nbsp;
                                                        &nbsp;
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        Open
                                                    </Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            < div className = "form-check custom-checkbox checkbox-success check-lg me-3" >
                                                <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customCheckBox3"
                                                required=""
                                                />
                                                <label
                                                className="form-check-label"
                                                htmlFor="customCheckBox3"
                                                ></label>
                                            </div>
                                            <div className="media-body">
                                                <div className='' style={{ display: "flex" }}>
                                                    <h5 className="mb-0">Bus Ticket</h5>&nbsp;&nbsp;
                                                        &nbsp;
                                                    <h5><strong className='ml-5 text-primary'> $15</strong></h5>
                                                </div>
                                                <div className="bootstrap-badge pt-sm-1">
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        City Bus
                                                        </Badge>
                                                        &nbsp;
                                                        &nbsp;
                                                    <Badge  bg=" badge-rounded" className='badge-outline-secondary'>
                                                        Out Side City
                                                    </Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button
                    onClick={() => setServiceListModal(false)}
                    variant="danger light"
                >
                    Close
                </Button>
                <Button variant="primary" onClick={() => setConfirmModal(false)}>Add</Button>
                </Modal.Footer>
            </Modal>

            {/* <!-- Modal --> */}
            <Modal className="fade" show={confirmModal}  centered>
                <Modal.Header>
                <Modal.Title>Confirm</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={() => setConfirmModal(false)}
                >
                </Button>
                </Modal.Header>
                <Modal.Body>
                    Are you sure want to add these services to your property?
                </Modal.Body>
                <Modal.Footer>
                <Button
                    onClick={() => setConfirmModal(false)}
                    variant="danger light"
                >
                    No
                </Button>
                <Button variant="primary">Yes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PropertyList;