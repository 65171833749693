import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import AdminService from "../../../services/AdminService";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import Loader from "../Loader";

const ThreeDot = () => {
    return (
        <>
            {" "}
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                    stroke="#3E4954"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                    stroke="#3E4954"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                    stroke="#3E4954"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

const ServiceCategory = () => {
    const [loader, setLoader] = useState(false);
    const [addScModal, setAddScModal] = useState(false);
    const [mainServices, setMainServices] = useState([]);
    const [confirmModalOpen, setConfirmModal] = useState(false);
    const [sercieCatList, setServiceCatList] = useState([]);
    const [serviceCatLoader, setServiceCatLoader] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [serviceCatId, setServiceCatId] = useState(null);
    const [serviceIdFilter, setServiceIdFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState('all');
    const [subServiceMainServiceId, setSubServiceMainServiceId] = useState({
        value: "",
        label: "Select..",
    });

    const [formData, setFormData] = useState({
        service_category_name: "",
        status: {
            value: "1",
            label: "Active",
        }
    })

    const errorsObj = {
        service_category_name: "",
        mainServiceId: ""
    }

    const [errors, setErrors] = useState(errorsObj);

    const Status = [
        {
            value: "1",
            label: "Active",
        },
        {
            value: "0",
            label: "Inactive",
        },
    ];

    const handleFilter = (e) => {
        setServiceCatList([]);
        setServiceCatLoader(true);
        console.log("-=-=-", e.target.value);
        setStatusFilter(e.target.value);
        fetchServiceCategoryList(serviceIdFilter, e.target.value);
    }

    const handleStatusChange = (e, name) => {
        const { value, label } = e;
        setFormData((prevData) => ({
            ...prevData,
            [name]: { value, label }
        }))
    }

    const clearFeilds = () => {
        setFormData({
            service_category_name: "",
            status: {
                value: "1",
                label: "Active",
            }
        })
        setSubServiceMainServiceId({
            value: "",
            label: "Select..",
        });
    }

    const setFeilds = (item) => {
        console.log("item = ", item)
        setFormData({
            service_category_name: item.category,
            status: {
                value: item.status,
                label: item.status == 1 ? "Active" : "Inactive",
            }
        })
        setSubServiceMainServiceId({
            value: item?.service_id,
            label: item?.service,
        });
    }

    const handleAddServiceCategory = () => {
        let error = false;
        const errorObj = { ...errorsObj };
        if (formData.service_category_name.trim() == "") {
            errorObj.service_category_name = "Please enter name";
            error = true;
        }

        if (subServiceMainServiceId.value == "") {
            errorObj.mainServiceId = "Please select main service";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        const passData = {
            category: formData.service_category_name,
            service_id: subServiceMainServiceId.value,
            status: formData.status.value
        }
        AdminService.AddServiceCategory(passData)
            .then((resp) => {
                console.log("resp = ", resp);
                if (resp.success) {
                    setAddScModal(false);
                    fetchServiceCategoryList(serviceIdFilter, statusFilter);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: resp?.message,
                    });
                }
            })
    }

    const handleEditServiceCategory = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (formData.service_category_name.trim() == "") {
            errorObj.service_category_name = "Please enter name";
            error = true;
        }

        if (subServiceMainServiceId.value == "") {
            errorObj.mainServiceId = "Please select main service";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        const passData = {
            category: formData.service_category_name,
            service_id: subServiceMainServiceId.value,
            status: formData.status.value
        }
        AdminService.UpdateServiceCategory(passData, serviceCatId)
            .then((resp) => {
                console.log("resp = ", resp)
                if (resp.success) {
                    fetchServiceCategoryList(serviceIdFilter, statusFilter);
                    setShowEditModal(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: resp?.message,
                    })
                }
            })
    }

    const handleDeleteServiceCat = () => {
        setConfirmModal(false);
        AdminService.DeleteServiceCategory(serviceCatId)
            .then((resp) => {
                console.log("resp = ", resp)
                if (resp.success) {
                    fetchServiceCategoryList(serviceIdFilter, statusFilter);
                    setConfirmModal(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: resp?.message,
                    })
                }
            })
    }

    const fetchMainServices = () => {
        AdminService.GetMainServices()
            .then((resp) => {
                if (resp.success) {
                    const formattedServices = resp?.data?.list?.map((service) => ({
                        value: service?.id,
                        label: service?.service,
                    }));
                    setMainServices(formattedServices);
                }
            })
            .catch((error) => {
                console.log("error GetMainServices=-=-=-=-=-=-=-=-=-=-=-", error);
            });
    }

    const fetchServiceCategoryList = (serviceIdFilterValue, statusFilterValue) => {
        setServiceCatList([]);
        setLoader(true);
        AdminService.GetAllServicesCategory(serviceIdFilterValue, statusFilterValue)
            .then((resp) => {
                console.log("resp", resp)
                if (resp.success) {
                    setServiceCatList(resp?.data?.list);
                }
            })
            .catch((error) => {
                console.log("error in fetch service category list", error);
            })
            .finally(function () {
                setLoader(false);
                setServiceCatLoader(false)
            });
    }

    useEffect(() => {
        fetchServiceCategoryList(serviceIdFilter, statusFilter);
    }, [])

    return loader ? <Loader /> : (
        <>
            <div className="form-head page-titles d-flex  align-items-center">
                <div className="me-auto  d-lg-block">
                    <h4 className="mb-1">Service Category</h4>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="#">Service Category List</Link>
                        </li>
                    </ol>
                </div>
                <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={statusFilter}
                    style={{
                        cursor: "pointer",
                        width: "fit-content",
                        fontSize: "14px",
                        backgroundColor: "var(--primary)",
                        color: "white",
                        marginRight: "10px"
                    }}
                    onChange={(e) => handleFilter(e)}
                >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
                <Button
                    className="btn btn-primary rounded light me-3 add-sub-service-btn"
                    onClick={() => {
                        setAddScModal(true);
                        clearFeilds();
                        fetchMainServices();
                    }}
                >
                    Add Service Category
                </Button>
            </div>
            <div className="col-xl-12">
                <div className="table-responsive table-hover fs-14 pm-list-table">
                    <div id="orderList" className="dataTables_wrapper no-footer">
                        <table
                            className="table display table-hover display dataTablesCard card-table dataTable no-footer"
                            id="example5"
                        >
                            <thead>
                                <tr role="row">
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Main Service</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    serviceCatLoader ? (
                                        <tr>
                                            <td colSpan="5">
                                                <ModalLoader />
                                            </td>
                                        </tr>
                                    ) : sercieCatList.length > 0 ? (
                                        sercieCatList.map((item, ind) => (
                                            <tr role="row" key={ind}>
                                                <td>{ind + 1}</td>
                                                <td>{item.category}</td>
                                                <td>{item.service}</td>
                                                <td>{item?.status == 1 ? <span className="badge badge-success">
                                                    Active
                                                </span> : <span className="badge badge-danger">
                                                    Inactive
                                                </span>}</td>
                                                <td>
                                                    {/* <Dropdown className="ms-auto">
                                                        <Dropdown.Toggle
                                                            variant=""
                                                            className="btn-link i-false"
                                                        >
                                                            <ThreeDot />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                            <Dropdown.Item
                                                                className="text-black"
                                                                onClick={() => {
                                                                    setShowEditModal(true);
                                                                    setFeilds(item);
                                                                    setServiceCatId(item.id);
                                                                    fetchMainServices();
                                                                }}
                                                            >
                                                                Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="text-black"
                                                                onClick={() => {
                                                                    setConfirmModal(true);
                                                                    setServiceCatId(item.id);
                                                                }}
                                                            >
                                                                Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown> */}
                                                    <Button
                                                        onClick={() => {
                                                            setShowEditModal(true);
                                                            setFeilds(item);
                                                            setServiceCatId(item.id);
                                                            fetchMainServices();
                                                        }}
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button>
                                                </td>

                                            </tr>
                                        ))) : (
                                        <tr role="row">
                                            <td colSpan="8" className="text-center">
                                                No sub-services available
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Add service modal */}
            < Modal
                className="fade"
                show={addScModal}
                onHide={() => {
                    // clearFeilds();
                    setAddScModal(false);
                }}
                centered >
                <Modal.Header>
                    <Modal.Title>Add Service Category</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => {
                            clearFeilds();
                            setAddScModal(false)
                        }}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Category Name</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Sub Service Name"
                                        name="service_category_name"
                                        value={formData.service_category_name}
                                        onChange={(e) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                service_category_name: e.target.value
                                            }));
                                            setErrors({
                                                ...errors,
                                                service_category_name: "",
                                            });
                                        }}
                                    />
                                    {errors.service_category_name && (
                                        <div className="text-danger fs-12">
                                            {errors.service_category_name}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Main Service</label>
                                <div>
                                    <Select
                                        options={mainServices}
                                        className="custom-react-select cursor-pointer"
                                        isSearchable={false}
                                        value={subServiceMainServiceId}
                                        onChange={(e) => {
                                            setSubServiceMainServiceId(e);
                                            setErrors({
                                                ...errors,
                                                mainServiceId: "",
                                            });
                                        }}
                                    />
                                    {errors.mainServiceId && (
                                        <div className="text-danger fs-12">
                                            {errors.mainServiceId}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    options={Status}
                                    className="custom-react-select cursor-pointer"
                                    name="status"
                                    value={formData.status}
                                    onChange={(e) => handleStatusChange(e, "status")}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            clearFeilds();
                            setAddScModal(false)
                        }}
                        variant="danger">
                        Close
                    </Button>
                    <Button
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault();
                            handleAddServiceCategory()
                        }}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* Edit service modal */}
            < Modal
                className="fade"
                show={showEditModal}
                onHide={() => {
                    // clearFeilds();
                    setShowEditModal(false);
                }}
                centered >
                <Modal.Header>
                    <Modal.Title>Edit Service Category</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => {
                            clearFeilds();
                            setShowEditModal(false)
                        }}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Category Name</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Sub Service Name"
                                        name="service_category_name"
                                        value={formData.service_category_name}
                                        onChange={(e) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                service_category_name: e.target.value
                                            }));
                                            setErrors({
                                                ...errors,
                                                service_category_name: "",
                                            });
                                        }}
                                    />
                                    {errors.service_category_name && (
                                        <div className="text-danger fs-12">
                                            {errors.service_category_name}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Main Service</label>
                                <div>
                                    <Select
                                        options={mainServices}
                                        className="custom-react-select cursor-pointer"
                                        isSearchable={false}
                                        value={subServiceMainServiceId}
                                        onChange={(e) => {
                                            setSubServiceMainServiceId(e);
                                            setErrors({
                                                ...errors,
                                                mainServiceId: "",
                                            });
                                        }}
                                    />
                                    {errors.mainServiceId && (
                                        <div className="text-danger fs-12">
                                            {errors.mainServiceId}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    options={Status}
                                    className="custom-react-select cursor-pointer"
                                    name="status"
                                    value={formData.status}
                                    onChange={(e) => handleStatusChange(e, "status")}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            clearFeilds();
                            setShowEditModal(false)
                        }}
                        variant="danger">
                        Close
                    </Button>
                    <Button
                        variant="success"
                        onClick={(e) => {
                            handleEditServiceCategory(e)
                        }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* delete service modal */}
            < Modal
                className="fade"
                show={confirmModalOpen}
                onHide={() => setConfirmModal(false)}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Sub Service</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setConfirmModal(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this service category? </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setConfirmModal(false)} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button onClick={handleDeleteServiceCat} variant="success"> Yes </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ServiceCategory;