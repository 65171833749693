import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AdminService from "../../../services/AdminService";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import profile from "../../../images/avatar/1.jpg";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";

const AllProeprtyManagers = () => {
  const [loader, setLoader] = useState(false);
  const [pmList, setPmList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pmStatus, setPmStatus] = useState(0);
  const [pmId, setPmId] = useState(0);
  const [statusOption, setStatusOption] = useState("all");
  const [pmListLoader, setPmListLoader] = useState(false);

  const handleStatusChange = () => {
    // Update the status locally
    setShowModal(false);

    const passData = {
      user_id: pmId,
      status: pmStatus,
    };

    AdminService.UpdatePmStatus(passData)
      .then((resp) => {
        console.log("response --", resp);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Property manager has been ${pmStatus == 1 ? "activated" : "deactivated"
              } successfully.`,
          }).finally(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error-----", error);
      });
  };

  const handleFilter = (e) => {
    setPmList([]);
    setPmListLoader(true);
    console.log("-=-=-", e.target.value);
    setStatusOption(e.target.value);
    fetchPmList(e.target.value);
  }

  const fetchPmList = (filter) => {
    AdminService.GetPmList(filter)
      .then((resp) => {
        console.log("pmlist = ", resp?.data?.list);
        if (resp?.data?.list) {
          setPmList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error in pm list = ", error);
      })
      .finally(() => {
        setLoader(false);
        setPmListLoader(false)
      });
  }

  useEffect(() => {
    setLoader(true);
    fetchPmList('all');
  }, []);

  return loader ? <Loader /> : (
    <>
      <div className="row">
        <div className="form-head page-titles d-flex  align-items-center">
          <div className="me-auto  d-lg-block d-block">
            <h4 className="mb-1">Property Managers</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/property-managers">Property Managers List</Link>
              </li>
            </ol>
          </div>
          <select
            name="cars"
            className="form-select form-control-lg rounded"
            value={statusOption}
            style={{
              cursor: "pointer",
              width: "fit-content",
              fontSize: "14px",
              backgroundColor: "var(--primary)",
              color: "white",
              marginRight: "10px"
            }}
            onChange={(e) => handleFilter(e)}
          >
            <option value="all">All</option>
            <option value="0">Pending</option>
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </select>
        </div>
        {
          pmListLoader ? (
            <div>
              <ModalLoader />
            </div>
          ) :
            pmList.length > 0 ? (pmList.map((item, ind) => (
              <div className="col-xl-3 col-xxl-4 col-lg-4 col-sm-6" key={item.id}>
                <div className="card user-card">
                  <div className="card-body pb-0">
                    <div className="d-flex mb-3 align-items-center">
                      <div className="dz-media me-3 rounded-circle">
                        <img src={item.profile_image != null ? item.profile_image : profile} alt="image" />
                      </div>
                      <div>
                        <h5 className="title mb-0">
                          <Link to={`/property-managers/${btoa(item.id)}`}>
                            {item.first_name + " " + item.last_name}
                          </Link>
                        </h5>
                        <div className="dz-meta">
                          <select
                            className={`form-select form-control-sm ${item.status === 1
                              ? "bg-success text-white"
                              : item.status === 0
                                ? "bg-info text-white"
                                : "bg-secondary text-white"
                              }`}
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              fontSize: "11px",
                            }}
                            value={item.status}
                            onChange={(e) => {
                              // handleStatusChange(e.target.value, item.id)
                              setShowModal(true);
                              setPmStatus(e.target.value);
                              setPmId(item.id);
                            }}
                          >
                            {item.status === 0 ? (
                              <>
                                <option value={0} style={{ fontSize: "13px" }}>
                                  Pending
                                </option>
                                <option value={1} style={{ fontSize: "13px" }}>
                                  Active
                                </option>
                                <option value={2} style={{ fontSize: "13px" }}>
                                  Inactive
                                </option>
                              </>
                            ) : item.status != 0 ? (
                              <>
                                <option value={1} style={{ fontSize: "13px" }}>
                                  Active
                                </option>
                                <option value={2} style={{ fontSize: "13px" }}>
                                  Inactive
                                </option>
                              </>
                            ) : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    <p className="fs-12">{item.about_me ? (item?.about_me.length > 100 ? `${item?.about_me.substring(0, 100)}...` : item?.about_me) : "-"}</p>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <span className="mb-0 title">Company</span> :
                        <span className="text-black ms-2">
                          {item.company_name ? item.company_name : "-"}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Email</span> :
                        <span className="text-black ms-2">
                          {item.email ? item.email : "-"}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Phone</span> :
                        <span className="text-black ms-2">
                          {item.phone ? item.phone : "-"}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Property Connected</span> :
                        <span className="text-black desc-text ms-2">
                          {item.number_of_properties ? item.number_of_properties : item.number_of_properties}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Service Connected</span> :
                        <span className="text-black desc-text ms-2">
                          {item.number_of_services ? item.number_of_services : item.number_of_services}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))) :
              (<div>No Property Managers found!</div>)}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            Want to {pmStatus == 1 ? "activate" : "deactivate"} this property
            manager?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={handleStatusChange} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllProeprtyManagers;
