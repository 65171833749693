import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Tab,
  Collapse,
  Card,
  Button,
  Modal,
  Table,
  Tooltip,
} from "react-bootstrap";

//** Import Image */
import ImageGallery from "../Omah/PropertyDetails/ImageGallery";

import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import AdminService from "../../../services/AdminService";

function PropertyDetails() {
  const { id } = useParams();
  const propertyId = atob(id);
  const [loader, setLoader] = useState(false);
  const [subServiceLoader, setSubServiceLoader] = useState(false);
  const [dettachSubServiceModal, setDettachSubServiceModal] = useState(false);
  const [attachSubServiceModal, setAttachSubServiceModal] = useState(false);
  const [addSubServiceModal, setAddSubServiceModal] = useState(false);
  const [addPmDescModal, setAddPmDescModal] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const descriptionRef = useRef(null);
  const [mainServiceList, setMainServiceList] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [subServicesOrg, setSubServicesOrg] = useState([]);
  const [subServicesIndex, setSubServicesIndex] = useState(null);
  const [passingData, setPassingData] = useState([]);
  const [mainServiceIdValue, setMainServiceIdValue] = useState(null);
  const [pmDesc, setPmDesc] = useState("");
  let errorsObj = { pmDesc: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [pmDescAdd, setPmDescAdd] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState(null);
  const [confirmModalOpen, setConfirmModal] = useState(false);
  const [subServiceDetail, setSubServiceDetail] = useState([]);
  const [subServiceDetailModal, setSubServiceDetailModal] = useState(false);

  const handleCheckboxChange = (event, index) => {
    setSubServicesIndex(index);
    const updatedServices = subServices.map(item => {
      if (item.id === index) {
        return { ...item, service_added: !item.service_added };
      }
      return item;
    });
    setSubServices(updatedServices);
    if (!event.target.checked) {
      setDettachSubServiceModal(true);
    } else {
      setAttachSubServiceModal(true);
    }
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getMainServicesByProeprty = () => {
    AdminService.GetMainServicesByProperty(propertyId)
      .then((resp) => {
        if (resp.success) {
          setMainServiceList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=-=-GetMainServicesByProperty=-=-=-=-=-=-=-=-=-", error);
      })
  }

  useEffect(() => {
    setLoader(true);
    AdminService.GetPropertyById(propertyId)
      .then((resp) => {
        if (resp.success) {
          setPropertyDetail(resp.data.detail);
          if (resp.data.detail.status != null) {
            setPropertyStatus(resp.data.detail.status)
          }
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      })
      .finally(() => {
        setLoader(false);
      })

    getMainServicesByProeprty();
  }, []);

  const handleAddService = (mainServiceId) => {
    setSubServices([]);
    setPassingData([]);
    setPmDesc("");
    setSubServicesIndex(null);
    setMainServiceIdValue(mainServiceId);
    setAddSubServiceModal(true);
    setSubServiceLoader(true)
    fetchSubServicList(mainServiceId);
  }

  const fetchSubServicList = (mainServiceId) => {
    AdminService.GetSubServicesByProperty(propertyId, mainServiceId)
      .then((resp) => {
        if (resp.success) {
          setSubServices(resp.data.list);
          setSubServicesOrg(resp.data.list);
        }
      })
      .catch((error) => {
        console.log("error =  ", error);
      })
      .finally(function () {
        setSubServiceLoader(false);
      })
  }

  const handleAttachService = (status, option) => {
    if (status == false && subServicesIndex != null) {
      const updatedServices = subServices.map(item => {
        if (item.id === subServicesIndex) {
          return { ...item, service_added: !item.service_added };
        }
        return item;
      });
      setSubServices(updatedServices);
    } else if (status == true && subServicesIndex != null) {
      setPassingData(prevData => [...prevData, { id: subServicesIndex, option: option, pm_desc: pmDesc }]);
    }
    setAttachSubServiceModal(false);
  }

  const handleDettachService = (status) => {
    if (status == false && subServicesIndex != null) {
      const updatedServices = subServices.map(item => {
        if (item.id === subServicesIndex) {
          return { ...item, service_added: !item.service_added };
        }
        return item;
      });
      setSubServices(updatedServices);
    } else if (status == true && subServicesIndex != null) {
      const updatedPassingData = passingData.filter(item => item.id !== subServicesIndex);
      setPassingData(updatedPassingData);
      const passData = {
        property_id: propertyId,
        service_id: mainServiceIdValue,
        sub_service_id: subServicesIndex
      }
      AdminService.DettachSubServiceToProperty(passData)
        .then((resp) => {
          if (resp.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Sub service has been dettached successfully.",
            });
            getMainServicesByProeprty();
          }
        })
        .catch((error) => {
          console.log("-====error====DettachSubServiceToProperty=", error);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Something went wrong. Please try again later.",
          });
        })
    }
    setDettachSubServiceModal(false);
  }

  const handleAttachSubService = () => {

    if (passingData.length <= 0) {
      console.log("please select sub services to add");
      return;
    }
    const passData = {
      property_id: propertyId,
      service_id: mainServiceIdValue,
      sub_service: passingData,
    }
    setAddSubServiceModal(false);
    AdminService.AttachSubServiceToProperty(passData)
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Sub service has been attached successfully.",
          });
          getMainServicesByProeprty();
        }
      })
      .catch((error) => {
        console.log("-====error=====", error);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: "Something went wrong. Please try again later.",
        });
      })
  }

  const handlePmDescModal = (subServiceId, value, option) => {
    setPmDesc("");
    if (option == 'add') {
      setPmDescAdd(true);
    } else {
      setPmDescAdd(false);
      setPmDesc(value);
    }
    setAddPmDescModal(true);
    setSubServicesIndex(subServiceId);
  }
  const handleAddPmDesc = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (pmDesc.trim() == "") {
      errorObj.pmDesc = "Please enter note.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const foundItem = subServicesOrg.find(item => item.id === subServicesIndex);
    if (foundItem) {
      if (foundItem.service_added == 1) {
        const passData = {
          property_id: propertyId,
          service_id: mainServiceIdValue,
          sub_service_id: subServicesIndex,
          pm_desc: pmDesc
        };
        AdminService.UpdatePmDescForSubServiceToProperty(passData)
          .then((resp) => {
            console.log("resp", resp);
            if (resp.success) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Note has been added successfully.",
              });
            }
          })
          .catch((error) => {
            console.log("-====error==UpdatePmDescForSubServiceToProperty===", error);
          })
          .finally(function () {
            setAddPmDescModal(false);
            fetchSubServicList(mainServiceIdValue);
          })
      } else {
        const updatedServices = subServices.map(item => {
          if (item.id === subServicesIndex) {
            return { ...item, pm_desc: pmDesc };
          }
          return item;
        });
        setSubServices(updatedServices);
        const updatedpassingData = passingData.map(item => {
          if (item.id === subServicesIndex) {
            return { ...item, pm_desc: pmDesc };
          }
          return item;
        });
        setPassingData(updatedpassingData);
      }
    }
    setAddPmDescModal(false);
  }

  const handleStatusChange = (e) => {
    console.log("----", e.target.value);
    setPropertyStatus(e.target.value);
    setConfirmModal(true);
  }

  const handleStatusChangeApi = (option) => {
    if (option) {
      console.log("callAPI")
      console.log("propertyStatus = ", propertyStatus)
      console.log("propertyId = ", propertyId)
      const apiData = {
        status: propertyStatus
      }
      AdminService.UpdatePropertyStatus(propertyId, apiData)
        .then((resp) => {
          console.log("resp=  ", resp)
          if (resp.success) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: resp.message,
            }).finally(function () {
              window.location.reload();
            });
          }
        })
    } else {
      console.log("Nooooooo callAPI")
      if (propertyStatus == 1) {
        setPropertyStatus(2);
      } else {
        setPropertyStatus(1);
      }
    }
    setConfirmModal(false);
  }

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10);
      const maxHeight = lineHeight * 4; // 4 lines
      if (descriptionRef.current.scrollHeight > maxHeight) {
        setShowButton(true);
      }
    }
  }, [propertyDetail?.description]);


  const [openMenu, setOpenMenu] = useState(false);
  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Property Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-list">Property</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">{propertyDetail?.name}</Link>
            </li>
          </ol>
        </div>
        <select
          name="cars"
          className="form-select form-control-lg rounded"
          value={propertyStatus}
          style={{
            cursor: "pointer",
            width: "fit-content",
            fontSize: "14px",
            backgroundColor: "var(--primary)",
            color: "white",
            marginRight: "1rem"
          }}
          onChange={(e) => handleStatusChange(e)}
        >
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <Link to="#" className="btn btn-sm btn-primary rounded light">
          Refresh
        </Link>
      </div>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6 mb-sm-5 mb-3">
                      <div className="front-view-slider">
                        {propertyDetail?.photos && propertyDetail?.photos?.length > 0 && (
                          <img
                            src={propertyDetail?.photos[0]?.url}
                            alt=""
                            className="object-fit-cover rounded-lg"
                            style={{ height: "450px", width: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 mb-sm-5 mb-3">
                      {propertyDetail?.photos && propertyDetail?.photos?.length > 0 && (
                        <div className="card" style={{ boxShadow: 'unset' }}>
                          <div className="card-body image-gallery-body">
                            <div className="image-gallery owl-carousel mt-4">
                              <ImageGallery photos={propertyDetail.photos} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="d-block mb-sm-5 mb-2">
                      <div className="me-auto mb-md-0">
                        <h3>{propertyDetail?.name}</h3>
                      </div>
                      <div className="description d-flex align-items-center mb-2">
                        {propertyDetail?.address && (<div style={{ marginRight: '10px' }}>
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9475 4.78947C8.94136 4.78947 7.02346 5.55047 5.61418 6.89569C4.20599 8.23987 3.42116 10.056 3.42116 11.9426C3.42116 14.7033 5.29958 17.3631 7.32784 19.4068C8.3259 20.4124 9.32653 21.2351 10.0786 21.8068C10.434 22.077 10.7326 22.29 10.9475 22.4389C11.1623 22.29 11.4609 22.077 11.8163 21.8068C12.5684 21.2351 13.569 20.4124 14.5671 19.4068C16.5954 17.3631 18.4738 14.7033 18.4738 11.9426C18.4738 10.056 17.689 8.23987 16.2808 6.89569C14.8715 5.55047 12.9536 4.78947 10.9475 4.78947ZM10.9475 23.2632C10.5801 23.8404 10.58 23.8403 10.5797 23.8401L10.5792 23.8398L10.5774 23.8387L10.5718 23.835L10.5517 23.8221C10.5345 23.8109 10.5097 23.7948 10.4779 23.7737C10.4143 23.7317 10.3224 23.6701 10.2063 23.5901C9.97419 23.43 9.64481 23.1959 9.25054 22.8962C8.46315 22.2977 7.41114 21.4333 6.35658 20.3707C4.27957 18.278 2.05273 15.2776 2.05273 11.9426C2.05273 9.67199 2.99797 7.50121 4.66932 5.90583C6.33959 4.31148 8.59845 3.42105 10.9475 3.42105C13.2965 3.42105 15.5554 4.31148 17.2256 5.90583C18.897 7.50121 19.8422 9.67199 19.8422 11.9426C19.8422 15.2776 17.6154 18.278 15.5384 20.3707C14.4838 21.4333 13.4318 22.2977 12.6444 22.8962C12.2501 23.1959 11.9207 23.43 11.6886 23.5901C11.5725 23.6701 11.4806 23.7317 11.417 23.7737C11.3979 23.7864 11.3814 23.7972 11.3675 23.8063C11.3582 23.8124 11.3501 23.8176 11.3432 23.8221L11.3232 23.835L11.3175 23.8387L11.3158 23.8398L11.3152 23.8401C11.315 23.8403 11.3148 23.8404 10.9475 23.2632ZM10.9475 23.2632L11.3148 23.8404C11.0907 23.983 10.8043 23.983 10.5801 23.8404L10.9475 23.2632Z" fill="#666666" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9474 10.2632C9.81378 10.2632 8.89479 11.1822 8.89479 12.3158C8.89479 13.4494 9.81378 14.3684 10.9474 14.3684C12.0811 14.3684 13.0001 13.4494 13.0001 12.3158C13.0001 11.1822 12.0811 10.2632 10.9474 10.2632ZM7.52637 12.3158C7.52637 10.4264 9.05802 8.89474 10.9474 8.89474C12.8368 8.89474 14.3685 10.4264 14.3685 12.3158C14.3685 14.2052 12.8368 15.7368 10.9474 15.7368C9.05802 15.7368 7.52637 14.2052 7.52637 12.3158Z" fill="#666666" />
                          </svg>
                          {propertyDetail?.address}
                        </div>)}
                        <div className="description d-flex align-items-center">
                          <i
                            class="fa-solid fa-star fa-xl me-2"
                          ></i>
                          {propertyDetail?.ratings} {propertyDetail?.total_reviews_count && '· ' + propertyDetail?.total_reviews_count + ' reviews'}
                        </div>
                      </div>
                    </div>
                    <div className="mb-sm-5 mb-2">
                      {propertyDetail?.bedroom && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <i className="flaticon-381-blueprint me-2" width={28}
                            height={19} />
                          {propertyDetail?.bedroom} Bedroom
                        </Link>
                      )}
                      {propertyDetail?.beds && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            width={28}
                            height={19}
                            viewBox="0 0 28 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.1 8.03846C25.7498 8.03846 28 10.2859 28 13.1538V17.5385H25.9V19H24.5V17.5385H3.5V19H2.1V17.5385H0V13.1538C0 10.3876 2.17398 8.03846 4.9 8.03846H23.1ZM21.7 0C23.5821 0 25.2005 1.57962 25.2 3.65385L25.2005 7.14522C24.5639 6.78083 23.8517 6.57692 23.1 6.57692L21.7 6.57618C21.7 5.32466 20.7184 4.38462 19.6 4.38462H15.4C14.8622 4.38462 14.3716 4.59567 14.0001 4.94278C13.629 4.59593 13.1381 4.38462 12.6 4.38462H8.4C7.24044 4.38462 6.30038 5.36575 6.3 6.57619L4.9 6.57692C4.14851 6.57692 3.43653 6.7807 2.8 7.14488V3.65385C2.8 1.68899 4.3096 0 6.3 0H21.7ZM12.6 5.84579C12.9799 5.84579 13.3 6.21117 13.3 6.57692L7.7 6.57618C7.7 6.12909 8.04101 5.84615 8.4 5.84615L12.6 5.84579ZM19.6 5.85107C19.9961 5.84578 20.2996 6.20175 20.3 6.57618H14.7C14.7 6.1227 15.041 5.84615 15.4 5.84615L19.6 5.85107Z"
                              fill="#9832FF"
                            />
                          </svg>
                          {propertyDetail?.beds} beds
                        </Link>
                      )}
                      {propertyDetail?.bathroom && (
                        <Link to="#" className="btn btn-primary light rounded me-2 mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            width={19}
                            height={22}
                            viewBox="0 0 19 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 10.4211L18.6388 12.249C18.0616 15.1706 15.4406 17.3684 12.5829 17.3684H11.6923L13.4082 22H2.28779V10.4211H19ZM5.14753 0C6.68536 0 8.00727 1.29706 8.00727 2.89474V7.52632H18.8743V8.68421H8.00727V9.26316H1.1439L1.14378 11.0001C0.481336 10.4964 0 9.64309 0 8.68421V2.89474C0 1.33809 1.25234 0 2.85974 0H5.14753Z"
                              fill="#9832FF"
                            />
                          </svg>
                          {propertyDetail?.bathroom} Bathroom
                        </Link>
                      )}
                      {propertyDetail?.guests && (
                        <Link to="#" className="btn btn-primary light rounded mb-sm-0 mb-2">
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 19 22"
                            fill="none"
                            stroke="#3B4CB8"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" fill="#9832FF"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          {propertyDetail?.guests} Guests
                        </Link>
                      )}
                    </div>
                    <h4>Description</h4>
                    <div ref={descriptionRef}
                      className={`description ${isExpanded ? 'expanded' : 'collapsed'}`}>
                      {propertyDetail?.description}
                    </div>
                    {showButton && (
                      <button onClick={toggleExpand} className="btn btn-link p-0">
                        {isExpanded ? 'Show less' : 'Show more'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Property Features</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.feature && propertyDetail?.feature?.length > 0) ? propertyDetail?.feature?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i className="las la-check-circle" />
                    {item?.feature}
                  </li>
                )) : (<>No Features as of now</>)}
              </ul>
            </div>
          </div>
        </div>

        {/* calncellation */}
        <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Cancellation</h3>
            </div>
            <div className="card-body">
              {propertyDetail?.cancellation}
            </div>
          </div>
        </div>

        {/* Rules */}
        <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Rules</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.rules && propertyDetail?.rules?.length > 0) ? propertyDetail?.rules?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i class="fa-solid fa-circle-info" />
                    {item?.rules}
                  </li>
                )) : (<>No Rules as of now</>)}
              </ul>
            </div>
          </div>
        </div>

        {/* security & place */}
        <div className="col-xl-12">
          <div className="card property-features">
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Security & Place</h3>
            </div>
            <div className="card-body">
              <ul>
                {(propertyDetail?.safety && propertyDetail?.safety?.length > 0) ? propertyDetail?.safety?.map((item, ind) => (
                  <li key={ind} style={{ width: 'unset', marginRight: '10px' }}>
                    <i class="fa-solid fa-circle-info" />
                    {item?.safety}
                  </li>
                )) : (<>No Security & Place as of now</>)}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title SlideToolHeader" onClick={() => setOpenMenu(!openMenu)} >
              <h3 className="fs-20 text-black mb-0">Reviews</h3>
              <div className="tools">
                <Link to={"#"} className={`handle ${openMenu ? "expand" : "collpase"}`}>
                  <i className="fal fa-angle-down" />
                </Link>
              </div>
            </div>
            <Collapse in={openMenu}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  {propertyDetail?.reviews && propertyDetail?.reviews.length > 0 ? (
                    propertyDetail.reviews.map((review, ind) => (
                      <div key={ind} className="mb-4">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-0 d-flex">
                            <i className="fa-solid fa-user text-primary me-2" />
                            <div>{review.name}&nbsp;</div>
                            <span className="text-muted text-truncate">
                              {" - "}{review.place.length > 25 ? `${review.place.substring(0, 25)}...` : review.place}
                            </span>
                          </h5>
                        </div>
                        <p className="mt-2">{review.review}</p>
                      </div>
                    ))
                  ) : (
                    <p>No Reviews as of now</p>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </div>

        <div className="col-xl-12">
          <Card>
            <div className="card-header border-0 pb-0">
              <h3 className="fs-20 text-black mb-0">Services</h3>
            </div>

            <Card.Body>
              <Tab.Container defaultActiveKey={"List"}>
                <Tab.Content>
                  <Tab.Pane eventKey={"List"}>
                    <div className="row">
                      {mainServiceList.map((item, ind) => (
                        <div
                          className="col-xl-3 col-xxl-3 col-md-4 col-sm-4 col-lg-4 m-b30"
                          key={ind}
                        >
                          <div className="property-card style-1">
                            <div className="dz-media">
                              <img className="fix-height-250" src={item.image} alt="/" />
                            </div>
                            <div className="dz-content">
                              <div className="dz-footer">
                                <h5 className="title">{item.service}</h5>
                                <div className="dz-meta">
                                  <ul>
                                    <li>
                                      <Link
                                        to={"#"}
                                        className="rent badge badge-sm badge-primary mt-2"
                                        onClick={() => handleAddService(item.id)}
                                      >
                                        Edit{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-plus"
                                          style={{ marginRight: 'unset' }}
                                        >
                                          <line x1="12" y1="5" x2="12" y2="19"></line>
                                          <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
                                <p className="mb-0 fs-10">
                                  <span
                                    className="badge-outline-secondary badge badge-rounded bs-pointer"
                                  >
                                    {item?.service_added} services added{" "}
                                  </span>
                                </p>
                                <p className="mb-0 fs-10 mt-0">
                                  <span
                                    className="badge-primary badge badge-rounded bs-pointer"
                                  >
                                    {item?.total_sub_services} services total{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* <!-- Add Sub Services Modal --> */}
      <Modal className="fade" show={addSubServiceModal} onHide={() => setAddSubServiceModal(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Add Sub Services</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddSubServiceModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Table responsive className="custom_service_table">
            <thead>
              <tr>
                <th className="width50 "></th>
                <th>No</th>
                <th>Provider</th>
                <th>Service Category</th>
                <th>Service Title</th>
                <th>Available At</th>
                <th>Description</th>
                <th>Notes</th>
                <th>Price</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {subServiceLoader ? (
                <tr>
                  <td colSpan="7">
                    <ModalLoader />
                  </td>
                </tr>
              ) : subServices?.length > 0 ? (
                subServices.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`checkbox-${ind}`}
                          required=""
                          checked={item.service_added}
                          onChange={(e) => handleCheckboxChange(e, item.id)}
                        />
                      </div>
                    </td>
                    <td>
                      <strong>{ind + 1}</strong>
                    </td>
                    <td>
                      <strong>{item.role == 2 ? "Self" : "Property Manager"}</strong>
                    </td>
                    <td>{item.service_category}</td>
                    <td>{item.service_title}</td>
                    <td>
                      {item?.available_at?.includes('check_in') && (<span className="badge badge-info me-1">
                        Check In
                      </span>)}
                      {item?.available_at?.includes('while_stay') && (<span className="badge bg-light text-dark me-1">
                        While Stay
                      </span>)}
                      {item?.available_at?.includes('check_out') && (<span className="badge bg-dark">
                        Check Out
                      </span>)}
                    </td>
                    <td className="text-truncate" style={{ maxWidth: '150px' }}>{item.desc_}</td>
                    {item.service_added == 1 ?
                      (item?.pm_desc == null || item?.pm_desc == "") ? (
                        <td>
                          <Link
                            to={"#"}
                            className="rent badge badge-sm badge-primary mt-2"
                            onClick={() => handlePmDescModal(item.id, item?.pm_desc, 'add')}
                          >
                            Add{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-plus"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </Link>
                        </td>
                      ) : (<td>
                        <Link
                          to={"#"}
                          className="rent badge badge-sm badge-primary mt-2"
                          onClick={() => handlePmDescModal(item.id, item?.pm_desc, 'edit')}
                        >
                          Edit{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-plus"
                          >
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                        </Link>
                      </td>) :
                      (
                        <td>
                          <span className="badge-outline-secondary badge badge-rounded">
                            Please attach service first!
                          </span>
                        </td>)}
                    <td>€{item.price}</td>
                    <td>
                      <Button
                        onClick={() => {
                          setSubServiceDetailModal(true);
                          setSubServiceDetail(item);
                        }}
                        className="btn btn-primary shadow btn-xs sharp me-1"
                      >
                        <i className="fa fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">No sub services found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setAddSubServiceModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => handleAttachSubService()} disabled={passingData.length > 0 ? false : true} variant="success"> Add </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Sub Service Detail Modal --> */}
      <Modal className="fade" show={subServiceDetailModal} onHide={() => setSubServiceDetailModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Sub Service Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setSubServiceDetailModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-md-3"><strong>Service Title:</strong></div>
              <div className="col-md-9">{subServiceDetail.service_title}</div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3"><strong>Provider:</strong></div>
              <div className="col-md-9">{subServiceDetail.role == 2 ? "Admin" : "Self"}</div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3"><strong>Service Category:</strong></div>
              <div className="col-md-9">{subServiceDetail.service_category}</div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3"><strong>Description:</strong></div>
              <div className="col-md-9">{subServiceDetail.desc_}</div>
            </div>
            {subServiceDetail.service_added == 1 && (
              <div className="row mb-3">
                <div className="col-md-3"><strong>Notes:</strong></div>
                <div className="col-md-9">{subServiceDetail.pm_desc ? subServiceDetail.pm_desc : "No additional notes"}</div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col-md-3"><strong>Price:</strong></div>
              <div className="col-md-9">€{subServiceDetail.price}</div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3"><strong>Available At:</strong></div>
              <td className="col-md-9">
                {subServiceDetail?.available_at?.includes('check_in') && (<span className="badge badge-info me-1">
                  Check In
                </span>)}
                {subServiceDetail?.available_at?.includes('while_stay') && (<span className="badge bg-light text-dark me-1">
                  While Stay
                </span>)}
                {subServiceDetail?.available_at?.includes('check_out') && (<span className="badge bg-dark">
                  Check Out
                </span>)}
              </td>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSubServiceDetailModal(false)} variant="danger"> Close </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Dettach Sub Services Modal --> */}
      <Modal className="fade" show={dettachSubServiceModal} centered>
        <Modal.Header>
          <Modal.Title>Remove Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleDettachService(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>Are you sure want to remove this service?</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleDettachService(false)}
            variant="danger"
          >
            {" "}
            No{" "}
          </Button>
          <Button onClick={() => handleDettachService(true)} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Attach Sub Services Modal --> */}
      <Modal className="fade" show={attachSubServiceModal} centered>
        <Modal.Header>
          <Modal.Title>Add Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleAttachService(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          This service for this Property or All Properties?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleAttachService(false)} variant="danger">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={() => handleAttachService(true, "for_all")} variant="info"> For All </Button>
          <Button onClick={() => handleAttachService(true, "this_only")} variant="warning">This only</Button>
        </Modal.Footer>
      </Modal>


      {/* <!-- Add Note Modal --> */}
      <Modal className="fade" show={addPmDescModal} centered>
        <Modal.Header>
          <Modal.Title>{pmDescAdd ? "Add" : "Edit"} Note</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddPmDescModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-4">
                <label className="form-label">Note</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add description"
                    value={pmDesc}
                    onChange={(e) => {
                      setPmDesc(e.target.value);
                      setErrors({
                        ...errors,
                        pmDesc: "",
                      });
                    }}
                  />
                  {errors.pmDesc && (
                    <div className="text-danger fs-12">{errors.pmDesc}</div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => { setPmDesc(""); setAddPmDescModal(false) }} variant="danger">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={() => handleAddPmDesc()} variant="success"> Save </Button>
        </Modal.Footer>
      </Modal>

      {/*property status change modal */}
      < Modal
        className="fade"
        show={confirmModalOpen}
        onHide={() => handleStatusChangeApi(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Property Status</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => handleStatusChangeApi(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to update this property status ? </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleStatusChangeApi(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => handleStatusChangeApi(true)} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal >

    </>
  );
}
export default PropertyDetails;
